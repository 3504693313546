import {HttpClient} from '@angular/common/http'
import {Inject, Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {filter, first, switchMap, tap} from 'rxjs/operators'
import {environment} from '../../environments/environment'
import {WINDOW} from '../application/window.provider'
import {PrintObject} from '../main/8-summary/summary.component'
import {Customer, CustomerService} from './customer.service'
import {DataService} from './data.service'

export const SIGN_PRINT = 0
export const SIGN_DIGITAL = 1
export const SIGNED_DIGITAL = 2
export const SIGNED_DIGITAL_CO_APPLICANT = 3

export interface PrintRequestResult {
  /**
   * The id of the document, lose this and all is lost
   */
  documentId: string
}

export interface GetDocumentLinkResponse {
  // A presigned link to the document
  signedUrl: string
}

export interface SignRequestInput {
  /**
   * The id of the document to sign.
   */
  documentId: string

  /**
   * The ID of the saved customer
   */
  customerId: string | undefined

  /**
   * Used to select texts in the backends.
   */
  scope: 'br'

  /**
   * This is always the string 'sign'
   */
  action: 'sign'

  /**
   * First name
   */
  firstName: string

  /**
   * last name
   */
  lastName: string

  /**
   * Personnummer, will be reformatted, but currently we think it is
   * 12 digit version required. But the backends will handle that
   */
  sub: string

  /**
   * Email
   */
  email: string

  /**
   * Mobile phone number in 46730274188 format
   */
  mobile: string

  /**
   * The s-ID of the user (handläggare) that is requesting the sign.
   * Will be removed from here and replaced in backends
   */
  sid: string

  /**
   * Need to indicate in signing that the user declines, or not.
   * Should be set to "ja" or "nej" - stupid!
   */
  declines: string

}

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(
    private httpClient: HttpClient,
    private customerService: CustomerService,
    private dataService: DataService,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   *
   */
  public sendForPrint(data: PrintObject): Observable<PrintRequestResult> {
    const url = `${environment.apiUrl}/print`
    return this.httpClient.put<PrintRequestResult>(url, data)
  }

  public getLink(id: string, sign = true): Observable<GetDocumentLinkResponse> {
    const url = `${environment.apiUrl}/print/${id}`
    return this.httpClient.get<GetDocumentLinkResponse>(url).pipe(
      tap((res: GetDocumentLinkResponse) => {
        this.injectedWindow.open(res.signedUrl, '_blank')
      }),
      filter(() => sign),
      switchMap(() => this.updateSignature(SIGN_PRINT))
    )
  }

  public getForm(data: any): Observable<GetDocumentLinkResponse> {
    const url = `${environment.apiUrl}/form`
    return this.httpClient.put<GetDocumentLinkResponse>(url, data).pipe(
      tap((res: GetDocumentLinkResponse) => {
        this.injectedWindow.open(res.signedUrl, '_blank')
      })
    )
  }

  /**
   * Creates a sign request, the sign request input has the document id etc.
   */
  public requestSign(data: SignRequestInput): Observable<void> {
    const url = `${environment.apiUrl}/print/${data.documentId}`
    return this.httpClient.put<void>(url, data)
  }

  /**
   * Makes sure the customer is saved and then updates the signatures
   *
   * @param index - The position in the signatures array to update
   */
  public updateSignature(index: number): Observable<any> {
    return this.saveCustomer().pipe(
      switchMap((customer: Customer) =>
        this.customerService.updateSignage(customer.id as string, index))
    )
  }

  /**
   * This is provided as a proxy for other that
   * want to save the customer (SummaryComponent) but does
   * not hold an instance of DataService
   */
  public saveCustomer(): Observable<Customer> {
    return this.dataService.save().pipe(first())
  }
}
