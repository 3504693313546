import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerToggle
} from '@angular/material/datepicker'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {DateTime} from 'luxon'
import {debounceTime, filter} from 'rxjs/operators'
import {KalpLoan} from '../../../model/kalp-loan'
import {TermsTexts} from '../../../model/terms-texts'
import {Property} from '../existing-loan.component'

@Component({
  selector: 'spb-loan',
  templateUrl: './loan.component.html',
  styleUrls: ['./loan.component.scss'],
  imports: [ReactiveFormsModule, MatFormField, MatLabel, MatInput, MatSuffix, MatSelect, MatOption, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatCheckbox, FormatNumberDirective]
})
export class LoanComponent implements OnInit, OnChanges {

  @Input() properties: Property[] = []

  @Input() loan: KalpLoan = new KalpLoan(0)

  /**
   * Selector to tell if we shall show "Löses" or "Villkorsändras"
   */
  @Input() conditions = false

  /**
   * Should we show terms and then potentially the calendar?
   */
  @Input() showTerms = false


  @Output() changes = new EventEmitter<KalpLoan>()

  public form = new FormGroup<any>({
    id: new FormControl(''),
    amount: new FormControl('', Validators.required),
    solve: new FormControl(false),
    mortgageAmount: new FormControl<number | null>(null, [Validators.required]),
    interestPercentString: new FormControl('', [Validators.required]),
    propertyName: new FormControl(),
    property: new FormControl(),
    isBlancoLoan: new FormControl(false),
    new: new FormControl(false),
    terms: new FormControl(3),
    expires: new FormControl<DateTime | null>(null)
  })

  // Export to template
  public termsTexts = TermsTexts.getValueArray()

  public ngOnInit(): void {
    this.form.valueChanges.pipe(
      debounceTime(300),
      filter(() => this.form.valid)
    ).subscribe({
      next: () => {
        const loan = new KalpLoan(0)
        loan.setFromInput(this.form.getRawValue())
        loan.interestPercent = this.form.controls.interestPercentString.value / 100
        loan.expires = this.form.controls.expires.value?.ts // <--- Woot
        this.changes.emit(loan)
      }
    })
  }


  public ngOnChanges(): void {
    this.form.patchValue(this.loan)
    this.form.controls.terms.setValue(this.loan.terms)
    this.form.controls.expires.setValue(this.loan.expires > 0 ? DateTime.fromMillis(this.loan.expires) : null)
    this.form.controls.interestPercentString.setValue((this.loan.interestPercent * 100).toFixed(2))
    this.form.controls.propertyName.enable()
    if (this.properties.length === 1) {
      this.form.controls.propertyName.setValue(this.properties[0].name)
      this.form.controls.property.setValue(this.properties[0].id)
      this.form.controls.propertyName.disable()
    }
    this.form.updateValueAndValidity()
  }
}
