import {formatNumber} from '@angular/common'

export class Mortgage {

  get mortgagePercent(): number {
    return this.mortgageRequirement
  }

  get mortgageAmount(): number {
    return Math.ceil(this.loanSum * this.mortgagePercent / 12 / 100) * 100
  }

  get rawSewage(): string {
    return formatNumber(Math.ceil(this.loanSum * this.mortgagePercent / 12), 'fr', '1.0-0')
  }

  /**
   *
   * @param mortgageRequirement - the mortgage requirement as 0.01 0.02 etc.
   * @param loanSum - The total amount of loans
   */
  constructor(
    private mortgageRequirement: number,
    private loanSum: number
  ) {
  }
}
