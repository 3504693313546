import {Directive, ElementRef, Input} from '@angular/core'
import html2canvas from 'html2canvas'
import {from, Observable} from 'rxjs'
import {ImageThing} from '../8-summary/summary.component'

@Directive({
  selector: '[spbChartDirective]'
})
export class ChartDirective {

  @Input() imageId = ''

  constructor(
    private elem: ElementRef) {
  }

  public draw(): Observable<ImageThing> {
    return from(html2canvas(this.elem.nativeElement, {
      backgroundColor: null, // Transparent background?
      logging: false
    }).then((canvas) => ({
      id: this.imageId,
      image: canvas.toDataURL('image/png')
    })))
  }
}
