import {LatestInterest} from '@sparbanken-syd/kalpylator'

export interface Terms {
  /**
   * The interest percent as in 0.0122 => 1,22 %
   */
  interestPercent: number

  /**
   * The terms in text like in "Bunden 1 år"
   */
  text: TTermText

  /**
   * The number of month the interest is fixed, 3 months or 24 = 2 years.
   */
  terms: TTermDuration
}


const TTypeDurations = [3, 12, 24, 36, 48, 60] as const
export type TTermDuration = typeof TTypeDurations[number]

const TTypeTexts = [
  'Rörlig (3 mån)',
  'Bunden 1 år',
  'Bunden 2 år',
  'Bunden 3 år',
  'Bunden 4 år',
  'Bunden 5 år'
] as const

export type TTermText = typeof TTypeTexts[number]

export class TermsTexts {

  private static termsMap = new Map<TTermDuration, Terms>(
    [
      [3, {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}],
      [12, {interestPercent: 0, text: 'Bunden 1 år', terms: 12}],
      [24, {interestPercent: 0, text: 'Bunden 2 år', terms: 24}],
      [36, {interestPercent: 0, text: 'Bunden 3 år', terms: 36}],
      [48, {interestPercent: 0, text: 'Bunden 4 år', terms: 48}],
      [60, {interestPercent: 0, text: 'Bunden 5 år', terms: 60}]
    ]
  )

  public static getThreeMonth(): Terms {
    return TermsTexts.termsMap.get(3) as Terms
  }

  public static getByMonths(months: TTermDuration): Terms {
    return TermsTexts.termsMap.get(months) as Terms
  }

  public static getValueArray(): Pick<Terms, 'text' | 'terms'>[] {
    const res: Pick<Terms, 'text' | 'terms'>[] = []
    for (const t of TermsTexts.termsMap.values()) {
      res.push({text: t.text, terms: t.terms})
    }
    return res
  }

  public static setLatestInterest(latestInterest: LatestInterest): any {
    const keys: Record<string, TTermDuration> =
      {
        'month3': 3,
        'year1': 12,
        'year2': 24,
        'year3': 36,
        'year4': 48,
        'year5': 60
      }
    Object.keys(latestInterest).forEach((key: string) => {
      if (keys[key]) {
        const t = TermsTexts.termsMap.get(keys[key]) as Terms
        t.interestPercent = (latestInterest as any)[key] / 100
      }
    })

  }
}
