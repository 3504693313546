import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {debounceTime} from 'rxjs/operators'
import {KalpylatorHelper} from '../../model/kalpylator-helper'
import {DataService, SaveData} from '../../services/data.service'
import {KalpIncome, KalpService} from '../../services/kalp.service'
import {NGService} from '../../services/ng.service'
import {ProposalService} from '../../services/proposal.service'
import {HouseholdComponent} from '../1b-household/household.component'

@Component({
  selector: 'spb-kalp',
  templateUrl: './kalp.component.html',
  styleUrls: ['./kalp.component.scss'],
  imports: [ReactiveFormsModule, MatLabel, MatFormField, MatInput, MatSuffix, HouseholdComponent, FormatNumberDirective]
})
export class KalpComponent implements OnInit, OnDestroy {

  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public houseHoldValid = false

  public form = new FormGroup({
    incomes: new FormArray<any>([], [Validators.minLength(1)]),
    otherCosts: new FormControl<number>(0, {nonNullable: true})
  })
  private data$ = new Subscription()
  private kalp$ = new Subscription()

  constructor(
    private kalpService: KalpService,
    private ngService: NGService,
    private dataService: DataService,
    private proposalService: ProposalService
  ) {
  }

  get incomes(): FormArray {
    return this.form.get('incomes') as FormArray
  }

  public ngOnInit(): void {
    this.kalp$ = this.kalpService.applicantCount$
      .subscribe({
        next: (count: number) => {
          this.resetForm(count)
          this.kalpService.kalpData$.next(this.form.getRawValue())
        }
      })

    this.form.valueChanges
      .pipe(
        debounceTime(500)
      )
      .subscribe({
        next: (values) => {
          // Emit to old Kalpylator
          this.kalpService.kalpData$.next(values)
          // Emit to NG
          this.ngService.ngIncomes$
            .next(values.incomes.map((i: KalpIncome, index: number) => KalpylatorHelper.convertIncomeToNG(i, index)))
          // Set validity
          this.stepValid.emit(this.form.valid && this.incomes.length > 0 && this.houseHoldValid)

          /**
           * Do a new proposal following each successfully submitted form, may be stupid
           */
          if (this.form.valid && !this.proposalService.proposal$.value.manual) {
            this.proposalService.makeProposal()
          }

        }
      })

    this.data$ = this.dataService.data$
      .pipe(
      ).subscribe({
        next: (data: SaveData) => {
          this.resetForm(data.incomes.incomes.length)
          // Not assignable to never[] ??
          this.form.patchValue(data.incomes as any)
        }
      })
  }

  public ngOnDestroy(): void {
    this.data$.unsubscribe()
    this.kalp$.unsubscribe()
  }

  public houseHoldValidEvent(event: boolean): void {
    this.houseHoldValid = event
    this.form.updateValueAndValidity()
  }

  public addIncome(): void {
    const income = new FormGroup({
      income: new FormControl<number | null>(null, [Validators.required, Validators.min(1)]),
      studyLoan: new FormControl<number>(0, {nonNullable: true}),
      taxFreeIncome: new FormControl<number>(0, {nonNullable: true})
    })
    this.incomes.push(income)
    this.form.updateValueAndValidity()
  }

  public removeIncome(): void {
    this.incomes.removeAt(1)
  }

  private resetForm(length: number): void {
    while (this.incomes.length < length) {
      this.addIncome()
    }
    if (this.incomes.length > length) {
      this.removeIncome()
    }
  }
}
