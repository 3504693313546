import {AsyncPipe, NgClass} from '@angular/common'
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {
  MatRadioButton,
  MatRadioChange,
  MatRadioGroup
} from '@angular/material/radio'
import {Subscription} from 'rxjs'
import {Plural} from '../../model/plural'
import {ControlService} from '../../services/control.service'
import {StaticDataService} from '../../services/static-data.service'

@Component({
  selector: 'spb-follow-advice',
  templateUrl: './follow-advice.component.html',
  styleUrls: ['./follow-advice.component.scss'],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, NgClass, MatRadioGroup, ReactiveFormsModule, MatRadioButton, AsyncPipe]
})
export class FollowAdviceComponent extends Plural implements OnInit, OnDestroy {

  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public advice: FormControl = new FormControl(undefined, [Validators.required])

  private follow$ = new Subscription()
  private declines$ = new Subscription()

  private declines = false

  get showOptions(): boolean {
    return !this.declines
  }

  constructor(
    protected override staticDataService: StaticDataService,
    public controlService: ControlService
  ) {
    super(staticDataService)
  }

  public ngOnInit(): void {
    this.init()
    this.follow$ = this.staticDataService.followAdvice$.pipe(
      // filter((follow: string) => !!follow)
    ).subscribe({
      next: (follow: string) => {
        this.advice.setValue(follow)
        this.stepValid.emit(true)
        // this.controlService.steps[7].next(true)
      }
    })

    this.declines$ = this.controlService.declines$.subscribe({
      next: (declines: boolean) => {
        this.declines = declines
        if (declines) {
          this.advice.setValue(`${this.plrDuNi} avstår från rådgivning`)
          this.staticDataService.followAdvice$.next(this.advice.value)
        } else if (!this.advice.value?.includes('följa mina råd')) {
          this.advice.setValue('')
        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.destroy()
    this.follow$.unsubscribe()
    this.declines$.unsubscribe()
  }

  public approve(event: MatRadioChange): void {
    this.staticDataService.followAdvice$.next(event.value)
    this.stepValid.emit(true)
    // this.controlService.steps[7].next(true)
  }
}
