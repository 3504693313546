<div class="spb-step-mongo">
  <div class="loans">
    <h3>Befintliga privatlån, konto- & kortkrediter</h3>

    @for (privateLoan of loans$ | async | loanFilter: true; track $index) {
      <div class="loan-holder mat-elevation-z2">
        <spb-loan
          (changes)="changes($event)"
          [conditions]="conditions"
          [loan]="privateLoan"
          [properties]="[]"></spb-loan>
        <button (click)="removeLoan(privateLoan.id)" color="warn"
                mat-stroked-button>
          <span class="material-symbols-outlined">delete</span>
        </button>
      </div>
    }

    <button (click)="addLoan(true)" class="add-button" color="accent"
            mat-stroked-button>Lägg till
    </button>

    <h3>Befintliga bolån</h3>

    @for (mortgageLoan of loans$ | async | loanFilter: false; track $index) {
      <div class="loan-holder mat-elevation-z2">
        <spb-loan
          (changes)="changes($event)"
          [loan]="mortgageLoan"
          [conditions]="conditions"
          [showTerms]="showTerms"
          [properties]="properties"></spb-loan>
        <button
          (click)="removeLoan(mortgageLoan.id)"
          color="warn"
          mat-stroked-button>
          <span class="material-symbols-outlined">delete</span>
        </button>
      </div>
    }
    <button (click)="addLoan(false)" class="add-button" color="accent"
            mat-stroked-button>Lägg till
    </button>
  </div>

  <div class="spb-step-info">
    <p>Ange kundens <span class="spb-mongo-highlight">befintliga lån</span>
      (samtliga lån i banken och i andra institut).</p>

    @if (conditions) {
      <p>Vid Omsättning/villkorsförändring av flera lån föreslår systemet alltid
        längst
        <span class="spb-mongo-highlight">räntebindning</span> på det lån som
        registreras sist av handläggaren.</p>
    }

    <p><span class="spb-mongo-highlight">Ränta</span> som visas är förslag på
      ränta för resp lånetyp. Ändra till faktisk
      ränta om information hämtas från kund.</p>

    <p>För att beräkna amorteringskrav mm ska <span class="spb-mongo-highlight">Bostad</span>
      anges vid resp lån. Saknar
      du en bostad gå tillbaka till steget innan och lägg till information.</p>

    @if (conditions) {
      <p>Markera de lån som ska <span class="spb-mongo-highlight">omsättas/villkorsändras</span>.
        Eventuella rörliga lån skall alltid markeras för att de ska omfattas av
        rådgivning.</p>
    }
  </div>
</div>
