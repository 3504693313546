import {HttpEvent, HttpHandlerFn, HttpRequest} from '@angular/common/http'
import {inject} from '@angular/core'
import {first, Observable, switchMap} from 'rxjs'
import {SECRET} from '../../environments/secret'
import {ConfigService} from '../services/config.service'

/**
 * Intercept all requests and add auth token. We should however
 * do this only on white listed requests so that we do not
 * send our tokens when not needed. In its simplest form this
 * assumes that all outgoing requests after the token is set
 * should have it.
 */
export const authInterceptor = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
  const configService = inject(ConfigService)
  return configService.accessToken$.pipe(
    first(),
    switchMap(token => {
      if (!token) {
        return next(req)
      }

      let headers = req.headers

      /**
       * Assign an x-api-key if the latest rate is going to be collected
       */
      if (req.url?.indexOf('/interests') !== -1) {
        headers = req.headers.set('X-Api-Key', SECRET)
      }

      const authReq = req.clone({
        headers: headers.set('Authorization', `Bearer ${token}`)
      })
      return next(authReq)
    })
  )
}