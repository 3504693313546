import {
  AsyncPipe,
  DatePipe,
  DecimalPipe,
  NgClass,
  NgTemplateOutlet
} from '@angular/common'
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog, MatDialogRef} from '@angular/material/dialog'
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelHeader
} from '@angular/material/expansion'
import {
  forkJoin,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription
} from 'rxjs'
import {
  catchError,
  delay,
  filter,
  first,
  map,
  switchMap,
  tap
} from 'rxjs/operators'
import {COLOR_LIST} from '../../application/data-types'
import {
  WaitDialogComponent,
  WaitDialogData
} from '../../common/wait-dialog/wait-dialog.component'
import {Advice} from '../../model/advice'
import {BuildingTypes, KalpBuilding} from '../../model/kalp-building'
import {KalpLoan} from '../../model/kalp-loan'
import {KalpResult} from '../../model/kalp-result'
import {Mortgage} from '../../model/mortgage'
import {Plural} from '../../model/plural'
import {ConfigService, ILogInState} from '../../services/config.service'
import {ControlService} from '../../services/control.service'
import {Customer, CustomerService} from '../../services/customer.service'
import {
  DebtQuota,
  KalpApplicant,
  KalpService,
  LegacyKalp,
  Scenarios
} from '../../services/kalp.service'
import {
  PrintRequestResult,
  PrintService,
  SignRequestInput
} from '../../services/print.service'
import {Questions} from '../../services/proposal.service'
import {
  ContactInfo,
  StaticDataService
} from '../../services/static-data.service'
import {ChartComponent} from '../6a-piechart/chart/chart.component'
import {ChartDirective} from '../chart-directive/chart.directive'
import {ChartLegendComponent} from '../chart-legend/chart-legend.component'
import {
  LoanDistributionChartComponent
} from '../loan-distribution-chart/loan-distribution-chart.component'
import {
  PreviewDialogComponent,
  PreviewResult
} from '../preview-dialog/preview-dialog.component'
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component'
import {ContactHolderComponent} from './contact-holder/contact-holder.component'
import {FillFormComponent} from './fill-form/fill-form.component'
import {LoanOverviewComponent} from './loan-overview/loan-overview.component'
import {SummaryPurposeComponent} from './purpose/summary-purpose.component'
import {LoanTableComponent} from './tables/loan-table.component'


export interface Suspect {
  /**
   * The name as "Daniel Bergdahl" of the person being interrogated
   */
  name: string

  /**
   * Personnummer - as per usual
   */
  personNummer: string
}

export interface PrintObject {
  user: {
    name: string | undefined
    email: string | undefined
  } | undefined
  title: string
  colorList: string[]
  applicants: Suspect[]
  purpose: any
  property: string
  propertyValue: number
  propertyLoans: number
  loanAmount: number
  thinking: string
  q1: string
  q2: string
  q3: string
  q4: string
  q5: string
  q6: string
  economy: string
  kalps: {
    name: string
    income: number
    cost: number
    kalp: any
  }[]
  reducedIncome?: string
  suitableHeading: string
  totalCost: [number, number]
  newLoans: {
    terms: number
    amount: number
    interestPercent: number
    mortgageAmount: number
    expiryDate: string
    active: boolean
    ratio: number
  }[]
  loanCost: [number, number, number]
  mortgageText: string
  mortgageDisclaimer: string
  adviceList: string[]
  adviceHeader: string
  advice: string
  comment: string
  images: ImageThing[]
  declines: boolean
  declinesText: string
  insurances: Insurances
}

export interface Insurances {
  informed: boolean,
  insurancesText: string,
  insurancesExtraText: string,
  insurancesTitle: string
}


export interface ImageThing {
  id: string
  image: string
}

@Component({
  selector: 'spb-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  imports: [NgTemplateOutlet, SummaryPurposeComponent, ChartLegendComponent, ChartComponent, ChartDirective, LoanDistributionChartComponent, LoanTableComponent, LoanOverviewComponent, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelContent, NgClass, ContactHolderComponent, MatButton, FillFormComponent, AsyncPipe, DecimalPipe, DatePipe]
})
export class SummaryComponent extends Plural implements OnInit, OnDestroy {

  @ViewChildren(ChartDirective) charts: QueryList<ChartDirective> = new QueryList<ChartDirective>()

  /**
   * If the suspects do not want to have any rådgivning
   * this is set to true and most of the template is hidden.
   */
  public declines = false

  public date = new Date()

  /**
   * Passed to the table component
   */
  public scenarioChange = false

  /**
   * The contact form is valid or not valid
   */
  public formStatus = true

  public comment = ''

  /**
   * We publish the subjects to the template here.
   */
  public suspects$: ReplaySubject<Suspect[]> = new ReplaySubject<Suspect[]>(1)

  // Simple string placeholders
  public q1 = ''
  public q2 = ''
  public q3 = ''
  public q4 = ''
  public q5 = ''
  public q6 = ''

  // This is so that we can print freely from the kalp
  public kalpSummary$: Subject<KalpResult[]> = new Subject<KalpResult[]>()
  // The new loans.
  public newLoans: KalpLoan[] = []
  // Total costs for new loan and new loan 2%
  public totalCost: [number, number] = [0, 0]
  // used in the summary table only
  public loanCost: [number, number, number] = [0, 0, 0]

  // Sum of loans for new loans, Amount, Mortgage (kr/mon), Interest (kr/mon)
  public reducedIncome?: string
  // Used for display and sent for print as well.
  public mortgageRequirementText = ''

  // A text of type 'Amorteringskravet på din bostad är x % (x kr/månad).'
  public building: KalpBuilding = new KalpBuilding()
  public mortgageDisclaimer = 'I förslaget ovan har vi angett att du amorterar enligt ditt amorteringskrav. ' +
    'Vi rekommenderar dock att du amorterar minst 0,5 % mer än ditt amorteringskrav då amortering minskar ' +
    'den totala räntekostnaden på ditt bolån. Dessutom minskar din skuld, vilket ger större trygghet om ' +
    'bostadspriserna faller eller din ekonomiska situation försämras, till exempel vid pension'
  /**
   * This is the primary Scenario
   */
  public scenario: Scenarios = Scenarios.Purchase
  /**
   * A string derived from plural and scenario;
   * 'Ni önskar låna  mer på er befintliga...'
   */
  public scenarioDescription = ''
  /**
   * Exporting this to the template
   */
  public colorList = COLOR_LIST
  // data in the template
  private kalp$: Subject<LegacyKalp[]> = new Subject<LegacyKalp[]>()
  private subscriptions$: Subscription[] = []

  private buildingType: BuildingTypes = BuildingTypes.purchase

  private kalps: LegacyKalp[] = [{} as any]

  private questions: Questions = {} as any

  /**
   * Purpose is the Scenario plus a list of items representing
   * amount of loans etc. see the Purpose component.
   */
  private purpose: any = {}

  constructor(
    public controlService: ControlService,
    public configService: ConfigService,
    public kalpService: KalpService,
    public override staticDataService: StaticDataService,
    public customerService: CustomerService,
    private printService: PrintService,
    private dialog: MatDialog
  ) {
    super(staticDataService)
  }

  public static normalizePhoneNumber(number: string): string {
    let res = number + ''

    res = res.replace(/\D/g, '')

    res = res.replace(/^4607/g, '467')

    while (res.charAt(0) === '0') {
      res = res.substring(1)
    }

    if (res.indexOf('46') === 0) {
      return `+${res}`
    }

    if (res.length === 0) {
      return ''
    }
    return `+46${res}`
  }

  public ngOnInit(): void {
    this.init() // For pluralisms

    let sub$: Subscription

    sub$ = this.kalpService.kalpResult$
      .pipe(
        switchMap((kalps: LegacyKalp[]) => {
          this.kalps = kalps
          const kalp = kalps[0]
          this.suspects$.next(kalp.applicants.map((applicant: KalpApplicant) => ({
            name: applicant.name,
            personNummer: applicant.personNummer
          })))
          this.reducedIncome = kalp.reducedIncome
          this.plural = kalp.applicants.length > 1
          return this.kalpService.getActiveBuilding()
        }),
        map((building: KalpBuilding) => {
          this.building = building
        })
      ).subscribe({
        next: () => {
          this.kalp$.next(this.kalps)
          const summaries: KalpResult[] = this.kalps
            .filter((kalp: LegacyKalp) => kalp.totalIncome) // IF this is zero, then we are in trouble
            .map((kalp: LegacyKalp) => new KalpResult(kalp))
          this.kalpSummary$.next(summaries)
          this.extractMonthlyCosts(this.kalps)
          this.setLoansEtc()
        }
      })
    this.subscriptions$.push(sub$)


    sub$ = this.staticDataService.questions$
      .pipe(switchMap((questions: Questions) => {
        this.questions = questions
        return this.staticDataService.plural$
      }))
      .subscribe({
        next: (plural: boolean) => {
          this.plural = plural
          const questionsMap: Record<string, any> = {
            q1: {
              '-1': {text: `Ej valt`},
              1: {text: `${this.plrDuNi} planerar att bo i bostaden i mindre än 1 år.`},
              3: {text: `${this.plrDuNi} planerar att bo i bostaden i ca 3 år.`},
              5: {text: `${this.plrDuNi} planerar att bo i bostaden i 3 till 5 år.`},
              10: {text: `${this.plrDuNi} planerar att bo i bostaden i 5 år eller mer.`}
            },
            q2: {
              '-1': {text: `Ej valt`},
              0: {text: `${this.plrDuNi} vill veta vad ${this.plrDuNi.toLowerCase()} skall betala för ditt boende varje månad.`},
              1: {
                text: `${this.plrDuNi} är ${this.plrBekvamBekvama} med att boendekostnaden varierar en del` +
                  ` över tid men inte för mycket.`
              },
              2: {text: `${this.plrDuNi} är ${this.plrBekvamBekvama} med att boendekostnaden varierar över tid.`}
            },
            q3: {
              '-1': {text: `Ej valt`},
              1: {
                text: `${this.plrDuNi} kan hantera stora förändringar utan att det påverkar ` +
                  `${this.plrDinEr.toLowerCase()} möjlighet att betala räntor och amorteringar på ${this.plrDinaEra} bolån.`
              },
              2: {
                text: `${this.plrDuNi} kan hantera vissa förändringar utan att det påverkar ` +
                  `${this.plrDinEr.toLowerCase()} möjlighet att betala räntor och amorteringar på ${this.plrDinaEra} bolån.`
              },
              3: {
                text: `${this.plrDuNi} har små marginaler och kan bara hantera mindre förändringar utan att det ` +
                  `påverkar ${this.plrDinEr.toLowerCase()} möjlighet att betala räntor och amorteringar på ${this.plrDinaEra} bolån.`
              }
            },
            q4: {
              '-1': {text: `Ej valt`},
              1: {
                text: `${this.plrDuNi} tror inte att hushållets ekonomi kommer att påverkas i stor grad de närmaste tre åren.`
              },
              2: {
                text: `${this.plrDuNi} tror att hushållets ekonomi kommer att påverkas på ett positivt sätt de närmaste tre åren.`
              },
              3: {
                text: `${this.plrDuNi} tror att hushållets ekonomi kommer att påverkas på ett negativt sätt de närmaste tre åren.`
              }
            },
            q5: {
              '-1': {text: `Ej valt`},
              1: {text: `${this.plrDuNi} kan hantera stora räntehöjningar under en lång period med hjälp av sparande eller annat.`},
              2: {text: `${this.plrDuNi} kan hantera räntehöjningar i viss omfattning och under en begränsad period med hjälp av sparande eller annat.`},
              3: {text: `${this.plrDuNi} kan endast hantera mindre räntehöjningar och under en begränsad period med hjälp av sparande eller annat.`}
            },
            q6: {
              '-1': {text: `Ej valt`},
              1: {text: `${this.plrDuNi} planerar att bo i bostaden i mindre än 1 år.`},
              2: {text: `${this.plrDuNi} planerar att bo i bostaden i 1-3 år.`},
              3: {text: `${this.plrDuNi} planerar att bo i bostaden i mer än 3 år.`}
            }
          }

          if (this.staticDataService.questions$.value.hasNewQuestions) {
            this.q1 = ''
            this.q2 = ''
            this.q3 = questionsMap.q3[this.questions.q3].text
            this.q4 = questionsMap.q4[this.questions.q4].text
            this.q5 = questionsMap.q5[this.questions.q5].text
            this.q6 = questionsMap.q6[this.questions.q6].text
          } else {
            this.q1 = questionsMap.q1[this.questions.q1].text
            this.q2 = questionsMap.q2[this.questions.q2].text
            this.q3 = ''
            this.q4 = ''
            this.q5 = ''
            this.q6 = ''
          }
        }
      })
    this.subscriptions$.push(sub$)

    sub$ = this.kalpService.scenarioChange$
      .pipe(
        filter((scenario: Scenarios | null) => !!scenario),
        switchMap((scenario: Scenarios | null) => {
          this.scenario = scenario as Scenarios
          this.scenarioChange = scenario === Scenarios.Conditions
          return this.staticDataService.plural$
        }),
        switchMap((plural: boolean) => {
          this.plural = plural
          const scenarioMap: Record<string, string> = {}
          scenarioMap[Scenarios.Purchase] = `${this.plrDuNi} planerar att köpa en ny bostad`
          scenarioMap[Scenarios.Increase] = `${this.plrDuNi} önskar låna mera mot ${this.plrDinEr.toLowerCase()} befintliga bostad`
          scenarioMap[Scenarios.Transfer] = `${this.plrDuNi} önskar flytta ${this.plrDinaEra} befintliga lån från en annan bank`
          scenarioMap[Scenarios.Conditions] = `${this.plrDuNi} önskar ändra villkor (räntebindning eller amortering) på befintliga lån`
          this.scenarioDescription = scenarioMap[this.scenario]
          return this.kalpService.kalpResult$
        }),
        filter((kalps: LegacyKalp[]) => kalps.length > 0)
      )
      .subscribe({
        next: (kalps: LegacyKalp[]) => {
          this.plural = kalps[0].applicants.length > 1
          if (this.scenario === Scenarios.Purchase) {
            this.buildingType = BuildingTypes.purchase
          } else {
            this.buildingType = BuildingTypes.keep
          }


          // Another special case handling :-/
          if (this.scenario === Scenarios.Conditions) {
            this.mortgageDisclaimer = 'I förslaget ovan har vi angett att du amorterar enligt nuvarande amorteringsplan' +
              ' på dina befintliga lån alt lägst enligt ditt amorteringskrav. Vi rekommenderar dock att du amorterar' +
              ' minst 0,5 % mer än ditt amorteringskrav då amortering minskar den totala räntekostnaden på ditt bolån.' +
              ' Dessutom minskar din skuld, vilket ger större trygghet om bostadspriserna faller eller din ekonomiska' +
              ' situation försämras, till exempel vid pension'
          }
        }
      })

    this.subscriptions$.push(sub$)

    sub$ = this.controlService.declines$.subscribe({
      next: (declines: boolean) => this.declines = declines
    })
    this.subscriptions$.push(sub$)

    sub$ = this.staticDataService.comment$.subscribe({
      next: (comment: string) => this.comment = comment
    })
    this.subscriptions$.push(sub$)
  }

  public ngOnDestroy(): void {
    this.destroy()
    this.subscriptions$.forEach((s: Subscription) => s.unsubscribe())
  }

  public print(): void {
    const ref = this.dialog.open(WaitDialogComponent, {
      data: {
        title: 'Hämtar dokument',
        message: ['Hämtar dokument, dokumentet öppnas i ett nytt fönster.']
      }
    })

    ref.afterOpened().pipe(
      switchMap(() => this.sendForPrint()),
      switchMap((result: PrintRequestResult) =>
        this.printService.getLink(result.documentId)),
      delay(2099) // Cannot test this :,-(
    ).subscribe({
      next: () => ref.close()
    })
  }

  public getImages(): Observable<ImageThing[]> {
    const subs$ = this.charts.map((chart: ChartDirective) => chart.draw())

    if (subs$.length === 0) {
      return of([])
    }
    return forkJoin(subs$)
  }

  /**
   * Called by the purpose component to set the purpose.
   * Only passed on to the print functions.
   */
  public purposeResult(res: any): void {
    this.purpose = res
  }

  public sendIt(): void {
    this.send().subscribe()
  }

  public unLockAdvice(): void {
    const data = {
      id: 1,
      message: `Kund ska åter välja om råd följs eller inte. Rådgivningsdokumentation ska produceras
    på nytt till kund. Är du säker på att du vill redigera denna rådgivning?`,
      title: 'Öppna för redigering'
    }

    this.dialog.open(ConfirmDialogComponent, {
      maxWidth: '440px',
      data
    })
  }

  /**
   * Send is called from the "Skicka för signering" button".
   */
  public send(): Observable<any> {
    let ref: MatDialogRef<WaitDialogComponent> = this.openDialog()
    // Open a dialog that says "wait"
    return ref.afterOpened().pipe(
      // Create a PDF on server based on the input
      switchMap(() => this.sendForPrint()),
      switchMap((data: PrintRequestResult) => {
        ref.close() // Just turn off the dialog
        return this.dialog.open(PreviewDialogComponent, {
          maxWidth: '500px',
          data: {documentId: data.documentId}
        }).afterClosed()
      }),
      switchMap((result: PreviewResult) => {
        if (result.status === 'sign') {
          //lock it for editing
          this.controlService.locked$.next(true)
          // Show another fancy dialog
          ref = this.dialog.open(WaitDialogComponent, {
            data: {
              title: 'Skickar för signering',
              message: ['Det här tar några sekunder...']
            }
          })
          // The user wants to print, step 1 is to save so that
          // we have a an Id on the customer.

          return this.printService.saveCustomer()
            .pipe(
              switchMap(() => this.suspects$),
              first(),
              switchMap((suspects: Suspect[]) =>
                forkJoin(
                  suspects.map((_suspect, index: number) =>
                    this.getSignRequestInput(result.documentId, index)
                      .pipe(
                        switchMap((input: SignRequestInput) => this.printService.requestSign(input))
                      )))
              ),
              tap(() => {
                ref.close()

                const data: WaitDialogData = {
                  title: 'Nu kan kunden signera dokumentet',
                  message: ['För kund som har vår Internetbank, går det bra att logga in med BankID ' +
                  'och välja Dokument/Dokument att signera. ',
                  'Det går även bra att gå via bankens inloggningssida ' +
                    'för Internetbanken och direkt välja att klicka på Dokument.'],
                  close: true
                }

                ref = this.dialog.open(WaitDialogComponent, {
                  maxWidth: '440px',
                  data
                })
              })
            )
        } else {
          return of({})
        }
      }),
      catchError((e: any) => {
        ref.close()
        this.generateError(e)
        throw e
      })
    )
  }

  private generateError(e: any) {
    const message = JSON.stringify(e)
    this.dialog.open(WaitDialogComponent, {
      data: {
        title: `Något gick fel!`,
        message: [`Lägg ett memo i ärendehanteringssystemet till PP&IT, förklara vad som hänt och bifoga skärmbild.`],
        error: message,
        close: true
      },
      width: '600px'
    })
  }

  private openDialog(): MatDialogRef<WaitDialogComponent> {
    return this.dialog.open(WaitDialogComponent, {
      data: {
        title: 'Förbereder dokument',
        message: ['Vi skapar dokument för utskrift, ett ögonblick']
      }
    })
  }

  private extractMonthlyCosts(kalps: LegacyKalp[]): void {
    const kalpList = [kalps[1], kalps[2]]
    kalpList
      .filter((kalp: LegacyKalp) => !!kalp.loans)
      .forEach((kalp: LegacyKalp, index: number) => {
        this.totalCost[index] = kalp.loans.loans
          .filter((loan: KalpLoan) => !loan.solve)
          .reduce((sum: number, loan: KalpLoan) => sum + loan.mortgageAmount + loan.interestAmount, 0)
      })
  }

  /**
   * Do necessary manipulation of new loans.
   */
  private setLoanCost(): void {
    this.loanCost = [0, 0, 0]
    const totalAmount = this.newLoans.reduce((sum: number, loan: KalpLoan) => sum + loan.amount, 0)
    this.newLoans.forEach((loan: KalpLoan) => {
      loan.ratio = loan.amount / totalAmount
      this.loanCost[0] += loan.amount
      this.loanCost[1] += loan.mortgageAmount
      this.loanCost[2] += loan.interestAmount
    })
  }

  private setMortgage(): void {
    this.kalpService.getDebtQuota().pipe().subscribe(
      {
        next: (dbq: DebtQuota) => {
          const mortgagePercent = dbq.amortizationRequirement
          const mortgage = new Mortgage(mortgagePercent, this.building.mortgageRequirementDebt)
          this.mortgageRequirementText =
            `Amorteringskravet på ${this.plrDinEr.toLowerCase()} bostad `
            + `är ${mortgagePercent * 100} % (${mortgage.rawSewage} kr/månad).`
        }
      }
    )
  }

  private setLoansEtc(): void {
    this.newLoans = this.building.loans.filter((loan: KalpLoan) => !loan.solve)
    this.setLoanCost()
    this.setMortgage()
  }

  private sendForPrint(): Observable<PrintRequestResult> {
    let images: ImageThing[]
    let suspects: Suspect[]
    let currentCustomer: Customer | undefined
    let followsAdvice: string
    return this.getImages().pipe(
      switchMap((imgs: ImageThing[]) => {
        images = imgs
        return this.suspects$
      }),
      switchMap((spcts: Suspect[]) => {
        suspects = spcts
        return this.staticDataService.followAdvice$
      }),
      switchMap((follow: string) => {
        followsAdvice = follow
        return this.customerService.currentCustomer$
      }),
      switchMap((customer: Customer | undefined) => {
        currentCustomer = customer
        return this.staticDataService.advice$
      }),
      first(),
      switchMap((adviceList: Advice[]) => {
        const printObject: PrintObject = {
          user: currentCustomer ? {
            name: currentCustomer.user?.name,
            email: currentCustomer.user?.email
          } : undefined,
          colorList: COLOR_LIST,
          title: 'Dokumentation av Bolånerådgivning',
          applicants: suspects,
          purpose: this.purpose,
          property: this.building.typeOfObject,
          propertyValue: this.building.objectValue,
          propertyLoans: this.building.currentLoanSum,
          loanAmount: this.building.activeLoanSum,
          thinking: `Så här tänker ${this.plrDuNi.toLowerCase()} kring ${this.plrDittErt.toLowerCase()} boende`,
          q1: this.q1,
          q2: this.q2,
          q3: this.q3,
          q4: this.q4,
          q5: this.q5,
          q6: this.q6,
          economy: `${this.plrDinEr} ekonomiska situation`,
          kalps: this.kalps.map((kalp: LegacyKalp) => {
            const kr = new KalpResult(kalp)
            return {
              name: kr.name,
              income: kr.totalIncome,
              cost: kr.totalCost,
              kalp: kr.kalp
            }
          }),
          reducedIncome: this.reducedIncome, // false or a text about reduction
          suitableHeading: `Portföljen passar ${this.plrDigEr.toLowerCase()} därför att`,
          totalCost: this.totalCost, // Sum monthly cost now and with 2% increase
          newLoans: this.newLoans.map((loan: KalpLoan) => ({
            terms: loan.terms,
            amount: loan.amount,
            interestPercent: loan.interestPercent,
            mortgageAmount: loan.mortgageAmount,
            expiryDate: loan.changeDate,
            active: loan.active,
            ratio: loan.ratio
          })), // All loans
          loanCost: this.loanCost, // Loan summary per month.
          mortgageText: this.mortgageRequirementText,
          mortgageDisclaimer: this.mortgageDisclaimer,
          adviceList: adviceList
            .filter((a: Advice) => !a.deleted)
            .map((a: Advice) => a.advice),
          adviceHeader: `Så här väljer ${this.plrDuNi.toLowerCase()} att göra med mina råd`,
          advice: followsAdvice,
          comment: this.comment,
          images,
          declines: this.declines,
          declinesText: followsAdvice, // Happens to be the correct text.
          insurances: this.controlService.insurances$.value
        }
        return this.printService.sendForPrint(printObject)
      })
    )
  }

  private getSignRequestInput(documentId: string, applicantIndex: number): Observable<SignRequestInput> {
    // Please see sdc signing component for details
    const result: SignRequestInput = {
      action: 'sign',
      scope: 'br',
      documentId,
      customerId: '',
      email: '',
      mobile: '',
      firstName: '',
      lastName: '', // replaced below.
      sid: '',
      sub: '', // Replaced by backend
      declines: this.declines ? 'ja' : 'nej' // This is a fulhack!
    }

    return this.customerService.currentCustomer$
      .pipe(
        first(),
        switchMap((customer: Customer | undefined) => {
          if (customer) {
            result.customerId = customer.id
          }
          return this.suspects$
        }),
        first(),
        switchMap((suspects: Suspect[]) => {
          const name = ((suspects[applicantIndex].name + '').trim()).split(' ')
          result.firstName = name[0]
          result.lastName = name[1]
          result.sub = suspects[applicantIndex].personNummer
          return this.staticDataService.contactInfo$
        }),
        first(),
        switchMap((contactInfo: [ContactInfo, ContactInfo]) => {
          Object.assign(result, contactInfo[applicantIndex])
          return this.configService.logInState$
        }),
        filter(Boolean),
        first(), // Not that it should ever happen, just to make sure
        map((logInState: ILogInState) => {
          result.sid = logInState.sId
          result.mobile = SummaryComponent.normalizePhoneNumber(result.mobile)
          return result
        })
      )
  }

}
