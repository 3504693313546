import {AsyncPipe, formatNumber} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatTab, MatTabContent, MatTabGroup} from '@angular/material/tabs'
import {ReplaySubject, Subscription} from 'rxjs'
import {KalpResult} from '../../model/kalp-result'
import {KalpService, LegacyKalp} from '../../services/kalp.service'
import {KalpDisplayComponent} from '../6b-1-kalp-display/kalp-display.component'

export interface KData {
  name: string
  kalp: LegacyKalp
  value: string
}

@Component({
  selector: 'spb-kalp-result',
  templateUrl: './kalp-result.component.html',
  styleUrls: ['./kalp-result.component.scss'],
  imports: [MatTabGroup, MatTab, MatTabContent, KalpDisplayComponent, AsyncPipe]
})
export class KalpResultComponent implements OnInit, OnDestroy {

  public kalps$ = new ReplaySubject<KData[]>(1)

  private k$ = new Subscription()

  constructor(
    public kalpService: KalpService
  ) {
  }

  public ngOnInit(): void {
    this.k$ = this.kalpService.kalpResult$.pipe().subscribe({
      next: (kalps: LegacyKalp[]) => {
        this.kalps$.next((kalps.map((kalp: LegacyKalp) => {
          const kalpResult = new KalpResult(kalp)
          return {
            name: kalp.name,
            kalp,
            value: formatNumber(kalpResult.kalp, 'fr', '1.0-0')
          }
        })))
      }
    })
  }

  public ngOnDestroy(): void {
    this.k$.unsubscribe()
  }
}
