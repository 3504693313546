import {DecimalPipe} from '@angular/common'
import {Component, OnDestroy, OnInit, signal} from '@angular/core'
import {
  MatExpansionPanel,
  MatExpansionPanelContent,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle
} from '@angular/material/expansion'
import {Subscription} from 'rxjs'
import {debounceTime, filter, switchMap} from 'rxjs/operators'
import {KalpBuilding} from '../../model/kalp-building'
import {KalpLoan} from '../../model/kalp-loan'
import {DebtQuota, KalpService, Scenarios} from '../../services/kalp.service'

@Component({
  selector: 'spb-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.scss'],
  imports: [MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelContent, DecimalPipe]
})
export class CalculatorComponent implements OnInit, OnDestroy {

  /**
   * Used to hide additional data in case och scenario
   * omsättning/villkorsändring (Scenarios.Conditions)
   */
  public scenarioChange = false

  /**
   * If scenario is Häjning
   */
  public scenarioIncrease = false

  public amortizationAmount = signal(0)

  public building: KalpBuilding = new KalpBuilding()

  public loan: KalpLoan = new KalpLoan(0)

  public debtQuota: DebtQuota = {} as any

  public existingAmortization = 0

  public additionalLoan: KalpLoan = new KalpLoan(0)

  private subscription$ = new Subscription()

  constructor(
    public kalpService: KalpService
  ) {
  }

  public ngOnInit(): void {
    this.subscription$ = this.kalpService.scenarioChange$.pipe(
      filter((s: Scenarios | null): s is Scenarios => s !== null),
      switchMap((scenario: Scenarios) => {
        this.scenarioChange = scenario === Scenarios.Conditions
        this.scenarioIncrease = scenario === Scenarios.Increase
        return this.kalpService.buildings$
      }),
      switchMap(() => this.kalpService.kalpResult$),
      switchMap(() => this.kalpService.getActiveBuilding()),
      switchMap((building: KalpBuilding) => {
        this.building = building
        return this.kalpService.loans$
      }),
      debounceTime(1000),
      switchMap((loans: KalpLoan[]) => {

        // All loans that are new and all loans connected to this property
        const newLoans = this.building.loans
        const loanSum = newLoans.reduce((sum: number, loan: KalpLoan) => sum + loan.amount, 0)
        this.loan = new KalpLoan(this.building.mortgageRequirementDebt)
        this.loan.property = this.building.id
        this.existingAmortization = loans
          .filter((l: KalpLoan) => l.property === this.building.id && !l.new && !l.solve)
          .reduce((sum: number, l: KalpLoan) => sum + l.mortgageAmount, 0)

        const onlyNewSum = loans
          .filter((l: KalpLoan) => l.new).reduce((sum: number, l: KalpLoan) => sum + l.amount, 0)

        this.additionalLoan = new KalpLoan(loanSum)
        this.additionalLoan.mortgageAmount = onlyNewSum / 120
        return this.kalpService.getDebtQuota()
      })
    ).subscribe({
      next: (dql: DebtQuota) => {
        this.debtQuota = dql
        this.loan.setMortgagePercent(dql.amortizationRequirement)
        this.amortizationAmount.set(this.loan.mortgageAmount)
      }
    })
  }

  public ngOnDestroy(): void {
    this.subscription$.unsubscribe()
  }
}
