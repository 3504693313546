<ngx-charts-pie-chart
  [doughnut]=false
  [gradient]="gradient"
  [labels]=false
  [legend]=false
  [results]="holder.chart"
  [scheme]="holder.color"
>
</ngx-charts-pie-chart>

