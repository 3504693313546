@if ((controlService.scenarioSelected$ | async) === true) {
  <div class="spb-step-mongo">
    <form [formGroup]="form" class="spb-form">
      <h3>Grunduppgifter</h3>
      <div class="holder">
        <div formArrayName="applicants">
          <div class="section">
            @for (applicant of applicants.controls; track $index) {
              <div [formGroupName]="$index" class="group">
                <mat-label>{{ $index === 0 ? 'Låntagare' : 'Medlåntagare' }}</mat-label>
                <mat-form-field class="margin-top-small">
                  <mat-label>Personnummer</mat-label>
                  <input formControlName="personNummer"
                         matInput
                         placeholder="ÅÅÅÅMMDD-NNNN"
                         type="tel">
                </mat-form-field>
                @if (applicantList[$index].controls.personNummer.valid) {
                  <mat-form-field>
                    <mat-label>Namn</mat-label>
                    <input
                      [placeholder]="$index === 0 ? 'Sökandes namn' : 'Medsökandes namn'"
                      formControlName="name"
                      matInput
                      type="text">
                  </mat-form-field>
                  <!-- This field is shown when no personummer is selected -->
                } @else {
                  <mat-form-field>
                    <mat-label>Namn</mat-label>
                    <input [disabled]="true"
                           matInput
                           placeholder="Ange personnummer först"
                           type="text">
                  </mat-form-field>
                }
                <!--
                 Start of Spouse selection, but only when they are not pre-selected
                 -->
                @if (!applicantSpouses.value && showExtraFields) {
                  <div>
                    <p class="lead-text">
                      Är {{ $index > 0 ? 'medsökande' : 'sökande' }} gift, har
                      en registrerad partner eller är sambo? </p>
                    <mat-form-field class="margin-top-small">
                      <mat-label>Välj i listan</mat-label>
                      <mat-select formControlName="hasSpouse"
                                  ngDefaultControl>
                        <mat-option [value]="null" disabled>Välj i listan
                        </mat-option>
                        <mat-option [value]="true">Ja</mat-option>
                        <mat-option [value]="false">Nej</mat-option>
                      </mat-select>
                      @if (applicant.get('hasSpouse')?.hasError('required')) {
                        <mat-error>
                          Du måste välja ett alternativ.
                        </mat-error>
                      }
                    </mat-form-field>
                    @if (applicant.get('hasSpouse')?.value) {
                      <div>
                        <mat-form-field>
                          <input formControlName="spouse"
                                 matInput
                                 placeholder="Ange dennes personnummer"
                                 type="tel">
                          <mat-error>Ogiltigt personnummer
                          </mat-error>
                        </mat-form-field>
                      </div>
                    }
                  </div>
                }

                <spb-duplicate [applicantNumber]="$index"
                               [group]="applicantList[$index].controls.personNummer"></spb-duplicate>
                <mat-checkbox class="space-bottom" color="primary"
                              formControlName="retired"
                              ngDefaultControl>Pensionär
                </mat-checkbox>
                @if (showExtraFields) {
                  <mat-form-field>
                    <mat-label>Arbetsgivare</mat-label>
                    <input
                      formControlName="employer"
                      matInput
                      type="text">
                  </mat-form-field>
                  <mat-checkbox class="space-bottom" color="primary"
                                formControlName="akassa"
                                ngDefaultControl>Medlem i Akassa
                  </mat-checkbox>
                }
              </div>
            }
          </div>
        </div>
        <mat-checkbox (click)="toggleApplicants()"
                      class="space-bottom"
                      color="primary"
                      [checked]="form.controls.applicants.value[1]"
                      formControlName="coApplicant" ngDefaultControl>
          Lägg till medlåntagare
        </mat-checkbox>
        @if (applicants.length > 1 && showExtraFields) {
          <div>
            <mat-checkbox
              [formControl]="applicantSpouses"
              class="space-bottom"
              color="primary"
              ngDefaultControl>Är ni som tillsammans söker lån gifta, partners
              eller sambo?
            </mat-checkbox>
          </div>
        }
        <mat-checkbox
          (change)="changeInsuranceCheckbox($event.checked)"
          formControlName="insurances"
          ngDefaultControl
          class="space-bottom"
          color="primary">{{ insuranceQuestion }}
        </mat-checkbox>

        <mat-checkbox
          (change)="this.controlService.decline()"
          formControlName="declines"
          ngDefaultControl
          class="space-bottom"
          color="primary">Avböjer rådgivning
        </mat-checkbox>
      </div>

      <mat-form-field [style.margin-top.px]="12" subscriptSizing="dynamic">
        <mat-label>Försäkringsskydd noteringar</mat-label>
        <textarea
          formControlName="insurancesExtraText"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          matInput></textarea>
      </mat-form-field>

    </form>

    <div class="spb-step-info">
      <p>Om <span class="spb-mongo-highlight">Vägval Borgo - Syd</span> använts
        och KALP har beräknats hämtas uppgifter
        in
        per automatik.</p>
      <p>Välj <span class="spb-mongo-highlight">Pensionär</span> om kunden gått
        i pension, detta för att kundens
        levnadskostnader ska reduceras i KALPen. </p>

      <p [style.margin-bottom]="0"><span
        class="spb-mongo-highlight">{{ insuranceQuestion }}</span>
      </p>
      <ul [style.margin-top]="0">
        <li>
          Lånekunder ska i mötet informeras om att banken tillhandahåller
          trygghetslösningar i form av livförsäkring och betalskydd.
        </li>

        <li>
          Teckning sker via Sparbanken Syds försäkringsportal och kunden
          godkänner med mobilt BankID.
        </li>

        <li>
          Länk till försäkringsportalen finns i internetbanken och i SydNet.
        </li>

        <li>
          Fritextfältet används för noteringar kring kundens försäkringsskydd.
          Exempelvis ”befintlig livförsäkring finns hos Folksam."
        </li>

      </ul>

      <p>Om kund <span class="spb-mongo-highlight">Avböjer rådgivning</span> ska
        namn och personnummer anges
        för att en dokumentation ska kunna produceras, systemet kommer
        automatiskt gå till sidan för sammanställning.</p>
    </div>
  </div>
}
