import {Component} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatDialog} from '@angular/material/dialog'
import {MatIcon} from '@angular/material/icon'
import {filter, first, switchMap} from 'rxjs/operators'
import {
  WaitDialogComponent
} from '../../../common/wait-dialog/wait-dialog.component'
import {KalpBuilding} from '../../../model/kalp-building'
import {KalpLoan} from '../../../model/kalp-loan'
import {
  KalpApplicant,
  KalpService,
  LegacyKalp,
  ObjectTypes
} from '../../../services/kalp.service'
import {PrintService} from '../../../services/print.service'

interface FormLoan {
  /**
   * Number of months, 3, 12, 24 etc.
   */
  terms: number

  /**
   * The loan amount
   */
  amount: number

  /**
   * The interest percent as 0.0324 = 3,24%
   */
  interestPercent: number

  /**
   * Mortgage amount in SEK
   */
  mortgageAmount: number
}

interface FormData {
  /**
   * A list of applicants containing name and sub
   */
  formApplicants: { name: string, sub: string }[]

  /**
   * The Skuldkvotsgrundande kreditbelopp
   */
  qualifyingCreditAmount: number

  /**
   * Loans to add to the form
   */
  loans: FormLoan[]

  /**
   * Loans to solve
   */
  solve: FormLoan[]

  /**
   * The fee if Bostadsrätt
   */
  fee: number

  /**
   * The run cost from the building
   */
  runCost: number

  /**
   * This is needed to know if fee or fee is displayed
   */
  type: ObjectTypes
}

@Component({
  selector: 'spb-fill-form',
  templateUrl: './fill-form.component.html',
  styleUrls: [],
  imports: [MatButton, MatIcon]
})
export class FillFormComponent {

  constructor(
    private dialog: MatDialog,
    private printService: PrintService,
    private kalpService: KalpService
  ) {
  }

  public form(): void {
    const ref = this.dialog.open(WaitDialogComponent, {
      data: {
        title: 'Fyller i...',
        message: ['Hämtar formulär, formuläret öppnas i ett nytt fönster.'],
        close: true
      }
    })
    let building: KalpBuilding
    ref.afterOpened()
      .pipe(
        switchMap(() => this.kalpService.getActiveBuilding()),
        switchMap((b: KalpBuilding) => {
          building = b
          return this.kalpService.kalpResult$
        }),
        filter((kalps: LegacyKalp[]) => Array.isArray(kalps) && kalps.length === 3),
        first(),
        switchMap((kalps: LegacyKalp[]) => {
          const formApplicants = kalps[1].applicants.map((applicant: KalpApplicant) => ({
            name: applicant.name,
            sub: applicant.personNummer
          }))

          const data: FormData = {
            formApplicants,
            qualifyingCreditAmount: kalps[1].debtQuota.loans,
            loans: kalps[1].loans.loans
              .filter((loan: KalpLoan) => loan.new)
              .map((loan: KalpLoan) => this.loanToFormLoan(loan)),
            solve: kalps[0].loans.loans
              .filter((loan: KalpLoan) => loan.solve)
              .map((loan: KalpLoan) => this.loanToFormLoan(loan)),
            fee: building.fee, // Only for Bostadsrätt
            runCost: building.runCost,
            type: building.typeOfObject // Villa or Bostadsrätt or Fritidshus
          }
          return this.printService.getForm(data)
        })
      ).subscribe({
        next: () => ref.close()
      })
  }

  private loanToFormLoan(loan: KalpLoan): FormLoan {
    return {
      terms: loan.terms,
      amount: loan.amount,
      interestPercent: loan.interestPercent,
      mortgageAmount: loan.mortgageAmount
    }
  }
}
