import {AsyncPipe} from '@angular/common'
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {FormControl, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatButton} from '@angular/material/button'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {FormatNumberDirective} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {debounceTime, filter} from 'rxjs/operators'
import {
  BuildingTypes,
  IKalpBuilding,
  KalpBuilding
} from '../../model/kalp-building'
import {DataService, SaveData} from '../../services/data.service'
import {KalpService, Scenarios} from '../../services/kalp.service'
import {NGService} from '../../services/ng.service'
import {PropertyComponent} from './property/property.component'

@Component({
  selector: 'spb-living',
  templateUrl: './living.component.html',
  styleUrls: ['./living.component.scss'],
  imports: [MatFormField, MatLabel, MatInput, ReactiveFormsModule, PropertyComponent, MatButton, AsyncPipe, FormatNumberDirective]
})
export class LivingComponent implements OnInit, OnDestroy {

  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public scenario: Scenarios = Scenarios.Purchase

  public buildings: KalpBuilding[] = []

  public valid = false

  public seekedAmount =
    new FormControl<number>(0, {
      nonNullable: true,
      validators: [Validators.required]
    })

  /**
   * Simple export to the template.
   */
  public scenarioTypes = [Scenarios.Purchase, Scenarios.Increase, Scenarios.Conditions, Scenarios.Transfer]

  private data$ = new Subscription()

  private scenario$ = new Subscription()

  constructor(
    public dataService: DataService,
    public ngService: NGService,
    private kalpService: KalpService
  ) {
  }

  ngOnInit(): void {
    this.scenario$ = this.kalpService.scenarioChange$
      .pipe(
        filter((s: Scenarios | null): s is Scenarios => s !== null))
      .subscribe({
        next: (scenario: Scenarios) => {
          this.scenario = scenario
          this.buildings = []
          if (scenario === Scenarios.Purchase) {
            this.buildings.push(new KalpBuilding('Nya bostaden', true, BuildingTypes.purchase))
            this.buildings.push(new KalpBuilding('Nuvarande boende som lämnas', false, BuildingTypes.leave))
            this.buildings[1].valid = true
          } else {
            this.buildings.push(new KalpBuilding('Uppgifter om bostaden', true, BuildingTypes.keep))
          }
          if (scenario === Scenarios.Conditions) {
            this.seekedAmount.clearValidators()
            this.seekedAmount.updateValueAndValidity()
          }
          this.kalpService.buildings$.next(this.buildings)
          this.setValidity()
        }
      })

    this.seekedAmount.valueChanges
      .pipe(
        debounceTime(300))
      .subscribe({
        next: (val: number) => {
          this.kalpService.loanAmount$.next(val)
          this.setValidity()
        }
      })

    this.data$ = this.dataService.data$
      .pipe(
      ).subscribe({
        next: (value: SaveData | null) => {
          if (value) {
            this.seekedAmount.setValue(value.livings.seekedAmount ?? 0)
            this.buildings = value.livings.buildings.map((building: IKalpBuilding) => {
              const b = new KalpBuilding()
              b.setFromInput(building)
              return b
            })
            this.kalpService.buildings$.next(this.buildings)
          } else {
            this.seekedAmount.setValue(0)
          }
        }
      })
  }

  public ngOnDestroy(): void {
    this.data$.unsubscribe()
    this.scenario$.unsubscribe()
  }

  public addBuilding(): void {
    this.buildings.push(new KalpBuilding('Övrigt boende', false, BuildingTypes.other))
    this.buildings[this.buildings.length - 1].valid = true
    this.setValidity()
  }

  public removeBuilding(index: number): void {
    this.buildings.splice(index, 1)
    this.setValidity()
  }

  public buildingChanges(building: KalpBuilding): void {
    const b = this.buildings.find((b1: KalpBuilding) => b1.id === building.id)
    Object.assign(b as any, building)
    this.setValidity()
  }

  private setValidity(): void {
    this.valid = this.buildings.reduce((v: boolean, building: KalpBuilding) => v && building.valid && this.seekedAmount.valid, true)
    this.stepValid.next(this.valid)
    // this.controlService.setStepValidity(3, this.valid)
    if (this.valid) {
      this.kalpService.buildings$.next(this.buildings)
    }
  }
}
