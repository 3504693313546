import {DatePipe, DecimalPipe} from '@angular/common'
import {Component, Input, OnChanges} from '@angular/core'
import {COLOR_LIST} from '../../../application/data-types'
import {KalpLoan} from '../../../model/kalp-loan'

interface ModifiedLoan extends KalpLoan {
  info: number
  position: number
}

@Component({
  selector: 'spb-loan-overview',
  templateUrl: './loan-overview.component.html',
  styleUrls: ['./loan-overview.component.scss'],
  imports: [DecimalPipe, DatePipe]
})
export class LoanOverviewComponent implements OnChanges {
  @Input() loans: KalpLoan[] = []
  public colorList = COLOR_LIST
  public modifiedLoans: ModifiedLoan[] = []

  public ngOnChanges() {
    if (this.loans.length > 0) {
      this.getPositions(this.loans)
    }
  }

  // Calculate the 'info' property for a loan
  private calcInfo(index: number) {
    const currentLoan = this.modifiedLoans[index]
    const previousLoan = this.modifiedLoans[index - 1]
    currentLoan.info = currentLoan.position

    if (previousLoan && currentLoan) {
      // Check if the difference in positions is less than x
      if (currentLoan.info - previousLoan.info < 15.5) {
        currentLoan.info = previousLoan.info + 15.5
      }
    }
  }

  // Calculate positions and info for loans
  private getPositions(loans: KalpLoan[]) {
    // We only want numbers, that's why we filter
    const expires = loans
      .filter(loan => loan.expires)
      .map((loan: KalpLoan) => loan.expires)

    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const minExpires = Math.min(today.getTime())
    const maxExpires = Math.max(...expires)

    // Calculate positions for loans
    this.modifiedLoans = loans.map((loan: KalpLoan) => ({
      ...loan,
      terms: loan.terms,
      position: ((loan.expires - minExpires) / (maxExpires - minExpires)) * 100
    }) as ModifiedLoan)
    // Calculate 'info' property for each loan
    this.modifiedLoans.forEach((loan: ModifiedLoan, index: number) => {
      this.calcInfo(index)
    })
  }
}
