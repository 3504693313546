export class Advice {
  /**
   * The advice as text
   */
  public advice: string

  /**
   * Indicates if this is manual, for what ever reason...
   */
  public manual = false

  /**
   * If deleted we can cross it over instead of removing it?
   */
  public deleted = false

  constructor(advice = '') {
    this.advice = advice
  }
}
