<title matDialogTitle>Klart!</title>
<div matDialogContent>
  <p>Vi har skapat ett dokument. Du kan nu välja att förhandsgranska eller att
    skicka för signering. </p>
  <p>Dokument för förhandsgranskning öppnas i ett nytt fönster.</p>
</div>
<mat-dialog-actions>
  <button [matDialogClose]="{status: 'cancel',  documentId: data.documentId}"
          color="warn"
          mat-stroked-button
  >Avbryt
  </button>
  <button (click)="preview()"
          color="accent"
          mat-stroked-button
  >Förhandsgranska
  </button>
  <button [matDialogClose]="{status: 'sign', documentId: data.documentId}"
          color="primary"
          mat-raised-button
  >Skicka för signering
  </button>
</mat-dialog-actions>
