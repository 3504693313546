import {AsyncPipe} from '@angular/common'
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms'
import {
  MatRadioButton,
  MatRadioChange,
  MatRadioGroup
} from '@angular/material/radio'
import {Subscription} from 'rxjs'
import {Plural} from '../../model/plural'
import {DataService, SaveData} from '../../services/data.service'
import {StaticDataService} from '../../services/static-data.service'

@Component({
  selector: 'spb-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
  imports: [ReactiveFormsModule, MatRadioGroup, MatRadioButton, AsyncPipe]
})
export class QuestionsComponent extends Plural implements OnInit, OnDestroy {
  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  public form = new FormGroup({
    q1: new FormControl<any>(-1),
    q2: new FormControl<any>(-1),
    q3: new FormControl<any>(-1),
    q4: new FormControl<any>(-1),
    q5: new FormControl<any>(-1),
    q6: new FormControl<any>(-1)
  })

  private data$ = new Subscription()
  private valid$: Subscription = new Subscription()

  constructor(
    public override staticDataService: StaticDataService,
    private dataService: DataService
  ) {
    super(staticDataService)
  }

  get q1result(): number {
    const q1Value = this.form.get('q1')?.value
    return +q1Value
  }

  get q2result(): number {
    const q2Value = this.form.get('q2')?.value
    return +q2Value
  }

  get q3result(): number {
    const q3Value = this.form.get('q3')?.value
    return +q3Value
  }

  get q4result(): number {
    const q4Value = this.form.get('q4')?.value
    return +q4Value
  }

  get q5result(): number {
    const q5Value = this.form.get('q5')?.value
    return +q5Value
  }

  get q6result(): number {
    const q5Value = this.form.get('q6')?.value
    return +q5Value
  }

  public changeRadioButton(event: MatRadioChange, question: number) {
    if (question === 1) {
      this.form.controls.q1.setValue(event.value)
    }
    if (question === 2) {
      this.form.controls.q2.setValue(event.value)
    }
    if (question === 3) {
      this.form.controls.q3.setValue(event.value)
    }
    if (question === 4) {
      this.form.controls.q4.setValue(event.value)
    }
    if (question === 5) {
      this.form.controls.q5.setValue(event.value)
    }
    if (question === 6) {
      this.form.controls.q6.setValue(event.value)
    }

    const questions = {
      hasNewQuestions: this.staticDataService.questions$.value.hasNewQuestions,
      q1: +this.form.controls.q1.value,
      q2: +this.form.controls.q2.value,
      q3: +this.form.controls.q3.value,
      q4: +this.form.controls.q4.value,
      q5: +this.form.controls.q5.value,
      q6: +this.form.controls.q6.value
    }
    this.staticDataService.questions$.next(questions)
  }

  /**
   * Quite annoying, but here I'm updating validators in the form based on whether we are using new questions or not.
   * @param newQuestions
   */
  public updateValidators(newQuestions: boolean | undefined) {
    // Clear validators for all controls
    this.form.get('q1')?.clearValidators()
    this.form.get('q2')?.clearValidators()
    this.form.get('q3')?.clearValidators()
    this.form.get('q4')?.clearValidators()
    this.form.get('q5')?.clearValidators()
    this.form.get('q6')?.clearValidators()

    if (newQuestions) {
      // Apply required and min validators for q3 to q6
      this.form.get('q3')?.setValidators([Validators.required, Validators.min(0)])
      this.form.get('q4')?.setValidators([Validators.required, Validators.min(0)])
      this.form.get('q5')?.setValidators([Validators.required, Validators.min(0)])
      this.form.get('q6')?.setValidators([Validators.required, Validators.min(0)])
    } else {
      // Apply required and min validators for q1 and q2
      this.form.get('q1')?.setValidators([Validators.required, Validators.min(1)])
      this.form.get('q2')?.setValidators([Validators.required, Validators.min(0)])
    }

    // Update the form control state
    this.form.get('q1')?.updateValueAndValidity()
    this.form.get('q2')?.updateValueAndValidity()
    this.form.get('q3')?.updateValueAndValidity()
    this.form.get('q4')?.updateValueAndValidity()
    this.form.get('q5')?.updateValueAndValidity()
    this.form.get('q6')?.updateValueAndValidity()
  }

  public ngOnInit(): void {
    this.init()
    /**
     * We check the form for validators when questions are updated
     */
    this.staticDataService.questions$.subscribe({
      next: questions => this.updateValidators(questions.hasNewQuestions)
    })

    this.valid$ = this.form.statusChanges.subscribe({
      next: (v: string) => {
        this.stepValid.emit(v === 'VALID')
        // this.controlService.setStepValidity(5, v === 'VALID')
      }
    })

    this.data$ = this.dataService.data$.subscribe({
      next: (data: SaveData | null) => {
        if (data) {
          this.form.patchValue(data.questions)
        } else {
          this.form.patchValue({q1: -1, q2: -1, q3: -1, q4: -1, q5: -1, q6: -1})
        }
      }
    })
  }

  public ngOnDestroy(): void {
    this.data$.unsubscribe()
    this.valid$.unsubscribe()
  }
}
