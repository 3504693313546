import {formatNumber} from '@angular/common'
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core'
import {KalpBuilding} from '../../../model/kalp-building'
import {Plural} from '../../../model/plural'
import {Scenarios} from '../../../services/kalp.service'
import {StaticDataService} from '../../../services/static-data.service'

@Component({
  selector: 'spb-summary-purpose',
  templateUrl: './summary-purpose.component.html',
  styleUrls: ['./summary-purpose.component.scss'],
  imports: []
})
export class SummaryPurposeComponent extends Plural implements OnInit, OnDestroy, OnChanges {

  @Input() building: KalpBuilding = new KalpBuilding()

  @Input() scenario: Scenarios = Scenarios.Purchase

  @Input() scenarioDescription = ''

  @Output() purposeResult: EventEmitter<any> = new EventEmitter<any>()

  /**
   * Until we make a type out of this... this works
   * as typings for the template.
   */
  public purpose = {
    title: '',
    items: [
      {text: 'Bostaden', value: ''},
      {text: 'Bostadens värde', value: ''}
    ],
    loans: [
      {text: 'Lånebelopp', value: ''}
    ]
  }

  constructor(
    protected override staticDataService: StaticDataService
  ) {
    super(staticDataService)
  }

  public ngOnInit(): void {
    super.init()
  }

  public ngOnDestroy(): void {
    super.destroy()
  }

  public ngOnChanges() {
    this.purpose = {
      title: '',
      items: [
        {text: 'Bostad', value: ''},
        {text: 'Bostadens värde', value: ''}
      ],
      loans: [
        {text: 'Lånebelopp', value: ''}
      ]
    }

    this.purpose.title = this.scenarioDescription
    this.purpose.items[0].value = this.building.typeOfObject
    this.purpose.items[1].value = formatNumber(this.building.objectValue, 'fr', '1.0-0') + ' kr'
    this.purpose.loans[0].value = formatNumber(this.building.activeLoanSum, 'fr', '1.0-0') + ' kr'

    if (this.scenario === Scenarios.Purchase) {
      this.purpose.loans[0].text = 'Sökt lånebelopp'
    }

    if (this.scenario === Scenarios.Increase) {
      this.purpose.loans[0].text = 'Befintliga lån'
      this.purpose.loans[0].value = formatNumber(this.building.allCurrentLoansOnBuilding, 'fr', '1.0-0') + ' kr'
      this.purpose.loans.push({
        text: 'Sökt lånebelopp',
        value: formatNumber(
          this.building.activeLoanSum + this.building.currentLoanSum - this.building.allCurrentLoansOnBuilding,
          'fr', '1.0-0') + ' kr'
      })
    }

    if (this.scenario === Scenarios.Transfer) {

      this.purpose.loans[0].text = 'Befintliga lån'
      this.purpose.loans[0].value = formatNumber(this.building.currentLoanSum, 'fr', '1.0-0') + ' kr'
      this.purpose.loans.push({
        text: 'Befintliga lån som flyttas',
        value: formatNumber(this.building.activeLoanSum, 'fr', '1.0-0') + ' kr'
      })
    }

    // When conditions all loans, both those that change and those that do not
    // Should be included here.
    if (this.scenario === Scenarios.Conditions) {
      this.purpose.loans[0].value = formatNumber(this.building.activeLoanSum + this.building.currentLoanSum, 'fr', '1.0-0') + ' kr'
    }
    this.purposeResult.emit(this.purpose)
  }
}
