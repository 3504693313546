import {CdkTextareaAutosize} from '@angular/cdk/text-field'
import {AsyncPipe} from '@angular/common'
import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core'
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators
} from '@angular/forms'
import {MatCheckbox} from '@angular/material/checkbox'
import {MatOption} from '@angular/material/core'
import {MatError, MatFormField, MatLabel} from '@angular/material/form-field'
import {MatInput} from '@angular/material/input'
import {MatSelect} from '@angular/material/select'
import {Applicant} from '@sparbanken-syd/kalpylator'
import {pnrValidator} from '@sparbanken-syd/sparbanken-syd-theme'
import {distinctUntilChanged, Subject, takeUntil} from 'rxjs'
import {filter, first, map, switchMap, tap} from 'rxjs/operators'
import {KalpylatorHelper} from '../../model/kalpylator-helper'
import {ConfigService, ILogInState} from '../../services/config.service'
import {ControlService} from '../../services/control.service'
import {
  DataService,
  SaveData,
  SaveDataApplicant
} from '../../services/data.service'
import {KalpService} from '../../services/kalp.service'
import {NGService} from '../../services/ng.service'
import {Insurances} from '../8-summary/summary.component'
import {DuplicateComponent} from './duplicate/duplicate.component'

/**
 * Helper so that we do not have to if this
 */
export const addOrRemoveValidators = (add: boolean, control: FormControl, validator: ValidatorFn | ValidatorFn[]): void => {
  if (add) {
    control.addValidators(validator)
  } else {
    control.removeValidators(validator)
  }
  control.updateValueAndValidity()
}

@Component({
  selector: 'spb-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  imports: [ReactiveFormsModule, MatLabel, MatFormField, MatInput, MatSelect, MatOption, MatError, DuplicateComponent, MatCheckbox, AsyncPipe, CdkTextareaAutosize]
})
export class GeneralComponent implements OnInit, OnDestroy {

  @Output() stepValid: EventEmitter<boolean> = new EventEmitter<boolean>()

  /**
   * Text displayed when asking about insurance coverage status.
   */
  public readonly insuranceQuestion = 'Avhandlat frågan om försäkringsskydd'

  /**
   * We added fields for spouse, akassa and employeer. We hide these for
   * now since the bank is not sure they should be there.
   */
  public showExtraFields = false

  /**
   * Marked as true if the applicants are spouses
   */
  public applicantSpouses = new FormControl<boolean>(false, {nonNullable: true})


  /**
   * This form must match the Application
   */
  public form = new FormGroup({
    applicants: new FormArray<any>([], [Validators.required]),
    coApplicant: new FormControl<boolean>(false, {nonNullable: true}),
    declines: new FormControl<boolean>({
      value: false,
      disabled: true
    }, {nonNullable: true}),
    insurances: new FormControl<boolean>(false, {
      nonNullable: true,
      validators: [Validators.requiredTrue]
    }),
    insurancesExtraText: new FormControl<string>('', {nonNullable: true}),
    applicantSpouses: this.applicantSpouses
  })

  /**
   * Used with takeUntil() to automatically cancel subscriptions when component is destroyed.
   */
  private destroy$ = new Subject<void>()

  private readonly pnrValidators: ValidatorFn[] =
    [Validators.required, Validators.minLength(10), Validators.maxLength(13), pnrValidator]

  constructor(
    public dataService: DataService,
    private configService: ConfigService,
    public controlService: ControlService,
    private kalpService: KalpService,
    private ngService: NGService
  ) {
  }

  get applicants(): FormArray {
    return this.form.get('applicants') as FormArray
  }

  get applicantList(): FormGroup[] {
    const groups: FormGroup[] = []
    for (let i = 0; i < this.form.controls.applicants.length; i++) {
      groups.push(this.form.controls.applicants.at(i) as FormGroup)
    }
    return groups
  }


  public ngOnInit(): void {
    this.addApplicant()

    this.form.statusChanges.pipe(
      tap((v: string) => {
        this.stepValid.emit(v === 'VALID')
        this.form.controls.declines.disable({onlySelf: true, emitEvent: false})
      }),
      filter(() => this.form.valid)
    ).subscribe({
      next: () => {
        const value = this.form.getRawValue()
        this.form.controls.declines.enable({onlySelf: true, emitEvent: false})

        if (this.applicantSpouses.value) {
          value.applicants[0].spouse = {
            sub: value.applicants[1].personNummer,
            name: value.applicants[1].name
          }
          value.applicants[0].hasSpouse = true
          value.applicants[1].spouse = {
            sub: value.applicants[0].personNummer,
            name: value.applicants[0].name
          }
          value.applicants[1].hasSpouse = true
        } else {
          this.applicantList.forEach((fg: FormGroup, index: number) => {
            delete value.applicants[index].spouse
            if (value.applicants[index].hasSpouse) {
              value.applicants[index].spouse = {sub: fg.controls.spouse.value}
            }
          })
        }

        value.applicantSpouses = this.applicantSpouses.value
        this.kalpService.kalpData$.next(value)

        // Emit stuff for NG
        const applicants: Applicant[] = value.applicants.map(KalpylatorHelper.convertApplicantToNG)
        this.ngService.ngApplicants$.next(applicants)

      }
    })

    this.dataService.data$.pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (raw: SaveData) => {
          const value: SaveData = JSON.parse(JSON.stringify(raw))
          this.removeApplicant()
          while (this.applicantList.length < value.applicants.applicants.length) {
            this.addApplicant()
          }
          // Is an array of existing applicants
          const pre = this.form.controls.applicants.getRawValue()
          pre.forEach((v: any, index: number) => {

            // Assign all values that are new
            if (value.applicants.applicants[index]) {
              Object.assign(v, value.applicants.applicants[index])
              // Put back old and new to new
              Object.assign(value.applicants.applicants[index], v)

              // Always set spouse to false due to that old saved
              // applications may have this set. Remove when/if
              // we activate spouse selection
              value.applicants.applicants[index].hasSpouse = false
            }
          })

          this.form.patchValue(value.applicants)

          // Since we save a complete spouse, we need to convert back to sub only
          value.applicants.applicants.forEach((a: SaveDataApplicant, index: number) => {
            this.applicantList[index].controls.spouse.setValue(a.spouse?.sub)
          })
        }
      })

    this.controlService.declines$.pipe(takeUntil(this.destroy$)).subscribe({
      next: (declines: boolean) => {
        this.form.controls.declines.setValue(declines)
      }
    })

    this.controlService.insurances$.pipe(
      filter(Boolean),
      distinctUntilChanged((prev, curr) => prev?.insurancesExtraText === curr?.insurancesExtraText),
      takeUntil(this.destroy$)
    ).subscribe({
      next: (insurances: Insurances) => {
        this.form.controls.insurances.setValue(insurances.informed)
        this.form.controls.insurancesExtraText.setValue(insurances.insurancesExtraText)
      }
    })

    this.form.controls.insurancesExtraText.valueChanges.pipe(
      distinctUntilChanged(),
      switchMap(newText =>
        this.controlService.insurances$.pipe(
          first(),
          tap(insurances => {
            this.controlService.insurances$.next({
              ...insurances,
              insurancesExtraText: newText
            })
          })
        )
      ),
      takeUntil(this.destroy$)
    ).subscribe()

    this.applicantSpouses.valueChanges.subscribe({
      next: (areSpouses: boolean) => {
        this.applicantList.forEach((fg: FormGroup) => {
          // If they are spouces we set the has spouse to false
          // If not we leave it as is
          if (areSpouses) {
            fg.controls.hasSpouse.setValue(false)
          }
        })
      }
    })

    this.form.controls.applicants.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(() => {

        const applicants = this.form.controls.applicants.value as Applicant[]

        // No validation needed if:
        // 1. Single applicant who is retired
        // 2. Two applicants who are both retired
        const shouldRemoveValidation =
          (applicants.length === 1 && applicants[0].retired) ||
          (applicants.length === 2 && applicants.every(a => a.retired))

        addOrRemoveValidators(
          !shouldRemoveValidation,
          this.form.controls.insurances,
          [Validators.requiredTrue]
        )
      })
  }

  public changeInsuranceCheckbox(informed: boolean) {
    this.configService.logInState$
      .pipe(
        first(),
        filter(Boolean),
        map(loggedInCustomer => this.createInsuranceText(informed, loggedInCustomer))
      )
      .subscribe(insurance => {
        this.controlService.insurances$.next(insurance)
      })
  }

  private createInsuranceText(informed: boolean, loggedInCustomer: ILogInState): Insurances {
    const insurancesText = `I samband med bolånerådgivningen har jag, ${loggedInCustomer.name}, ` +
      `lämnat information om möjligheten att teckna liv- och betalskyddsförsäkring.`

    return {
      informed,
      insurancesText,
      insurancesExtraText: this.form.controls.insurancesExtraText.value,
      insurancesTitle: this.insuranceQuestion
    }
  }


  public ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public toggleApplicants(): void {
    this.applicants.length === 1 ? this.addApplicant() : this.removeApplicant()
    this.kalpService.applicantCount$.next(this.applicants.length)
  }

  public addApplicant(): void {
    const applicant = new FormGroup({
      personNummer: new FormControl<string>('', this.pnrValidators),
      name: new FormControl<string>('', [Validators.required]),
      retired: new FormControl<boolean>(false),
      akassa: new FormControl<boolean>(false),
      employer: new FormControl<string>('' /* Validators.required */),
      hasSpouse: new FormControl<boolean | null>(false /* Validators.required*/),
      spouse: new FormControl<string>('', {nonNullable: true})
    })

    applicant.controls.hasSpouse.valueChanges
      .pipe(filter((v: boolean | null): v is boolean => v !== null))
      .subscribe({
        next: (value: boolean) => {
          addOrRemoveValidators(value, applicant.controls.spouse, this.pnrValidators)
        }
      })
    this.applicants.push(applicant)
  }

  public removeApplicant(): void {
    this.applicants.removeAt(1)
    // They can no longer be spouses
    this.applicantSpouses.setValue(false)
  }
}
