import {DecimalPipe} from '@angular/common'
import {Component, OnInit} from '@angular/core'
import {KalpResult} from '../../model/kalp-result'
import {KalpService, LegacyKalp} from '../../services/kalp.service'
import {ChartComponent} from './chart/chart.component'

@Component({
  selector: 'spb-piechart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  imports: [ChartComponent, DecimalPipe]
})
export class PieChartComponent implements OnInit {

  public kalps: KalpResult[] = []

  constructor(
    private kalpService: KalpService
  ) {
  }

  ngOnInit(): void {
    this.kalpService.kalpResult$
      .subscribe({
        next: (kalps: LegacyKalp[]) => {
          this.kalps = kalps
            .map((k: LegacyKalp) => new KalpResult(k))
        }
      })
  }
}

