import {Injectable} from '@angular/core'
import {BehaviorSubject, distinctUntilChanged, Observable} from 'rxjs'
import {debounceTime, filter, first, map, switchMap} from 'rxjs/operators'
import {KalpBuilding} from '../model/kalp-building'
import {KalpLoan} from '../model/kalp-loan'
import {Mortgage} from '../model/mortgage'
import {Terms, TermsTexts} from '../model/terms-texts'
import {DebtQuota, KalpService, LegacyKalp, Scenarios} from './kalp.service'
import {StaticDataService} from './static-data.service'

export interface Proposal {
  /**
   * This is a list of loans as we have "calculated" them
   */
  loans: KalpLoan[]

  /**
   * This is the target amount that we work against, eg. someone wants a loan of SEK 3 000 000
   * that is what we want to split into several loans.
   */
  loanAmount: number

  /**
   * Set this to true if you have made your own proposal
   */
  manual: boolean
}

export interface Questions {
  /**
   * First legacy question, 1, 3, 5, 10 years you expect to live in the new property
   */
  q1: number

  /**
   * Second legacy question, How much can the cost vary, 0 - little, 1, some, 2 a lot
   */
  q2: number

  /**
   * Q3: What if a major event impacts your income or expenses?, 1 - little, 2, some, 3 a lot
   */
  q3: number

  /**
   * Q4: Do you think your household's finances will be significantly affected in the next years?
   * 1 - no, 2 yes positive, 3 yes negative
   */
  q4: number

  /**
   * Q5: Does the household have savings or other investments to cope with increased interest costs?
   * 1 - yes, 2 some, 3 no
   */
  q5: number

  /**
   * Q7: How long do you plan to stay in the property?
   * 1 - less than a year, 2 more than a year
   */
  q6: number

  /**
   * Set this to true if the customer has proposal based on new questions (autumn 2023)
   */
  hasNewQuestions?: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ProposalService {

  /**
   * This is where we publish our proposal
   *
   */
  public proposal$ = new BehaviorSubject<Proposal>({
    loanAmount: 0,
    loans: [],
    manual: false
  })

  /**
   * The current loan amount is set by someone...
   * Actually it is (was) set by the living component...
   */
  private loanAmount = 0

  private questions: Questions = {
    q1: -1,
    q2: -1,
    q3: -1,
    q4: -1,
    q5: -1,
    q6: -1
  }

  private scenario: Scenarios | null = Scenarios.Purchase

  private twoPercent = 0

  /**
   * Once the advisor has made changes, all is fixed until
   * explicitly not so.
   */
  private fixed = false

  constructor(
    private kalpService: KalpService,
    private staticDataService: StaticDataService
  ) {

    this.proposal$.pipe(
      filter((proposal: Proposal) => !!proposal)
    ).subscribe({
      next: (proposal: Proposal) => this.fixed = proposal.manual
    })

    this.kalpService.kalpResult$.pipe(
      filter((kalps: LegacyKalp[]) => kalps && kalps.length === 3)
    ).subscribe({
      next: (kalps: LegacyKalp[]) => {
        this.twoPercent = kalps[2].kalp
      }
    })

    /**
     * Reset the questions (with reset in staticDataService) for the next customer
     */
    this.staticDataService.questions$.subscribe((questions: Questions) => this.questions = questions)

    /**
     * Normally we make a new loan if scenario, questions or loan
     * amount changes/emits
     */
    this.staticDataService.questions$.pipe(
      // Filter based on conditions:
      // - If we have new questions, q3 to q6 are needed
      // - Otherwise, q1 and q2 are needed
      filter((questions: Questions) => {
        const hasNewQuestions = questions.hasNewQuestions

        if (hasNewQuestions) {
          // When we have new questions, all of q3 to q6 should not be -1
          return questions.q3 !== -1 && questions.q4 !== -1 && questions.q5 !== -1 && questions.q6 !== -1
        } else {
          // When we don't have new questions, q1 and q2 should not be -1
          return questions.q1 !== -1 && questions.q2 !== -1
        }
      }),
      switchMap((questions: Questions) => {
        this.questions = questions
        return this.kalpService.loanAmount$
      }),
      switchMap((loanAmount: number) => {
        this.loanAmount = loanAmount
        return this.kalpService.scenarioChange$
      }),
      debounceTime(1000),
      // Heads up, this does pass if manual!
      filter((scenario: Scenarios | null) => !!scenario && !this.fixed)
    ).subscribe({
      next: ((scenario: Scenarios | null) => {
        this.proposal$.next({loanAmount: 0, loans: [], manual: false})
        this.scenario = scenario

        /**
         * Stupid, but i need to update twice, else I can't get it right when I change answers in questions :(
         */
        this.update()
        this.update()
      })
    })

    /**
     * Listens for changes when loanAmount > 0 and 'fixed' is false (not manual change).
     * This will trigger KALP update with the lowest term, without requiring answers to all questions.
     */
    this.kalpService.loanAmount$
      .pipe(distinctUntilChanged())
      .subscribe({
        next: loanAmount => {
          if (loanAmount > 0 && !this.fixed) {
            this.loanAmount = loanAmount
            this.update()
          }
        }
      })
  }

  public unlockProposal(): void {
    this.proposal$.pipe(
      first(),
      map((proposal: Proposal) => {
        this.fixed = false
        proposal.manual = false
        this.proposal$.next(proposal)
        this.update()
      })
    ).subscribe()
  }

  public legacyTerms(loans: KalpLoan[]) {
    const terms1: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms2: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms3: Terms = {interestPercent: 0, text: 'Rörlig (3 mån)', terms: 3}
    const terms: Terms[] = [terms1, terms2, terms3]
    this.getLoansFromQ1(terms)
    // How long are you planning on living in the new house?

    // 1 = 1 year or less, all loans shall be three months, no exceptions
    if (this.questions.q1 === 1) {
      return this.setTerms(loans, terms)
    }

    // In the case of "Villkorsändring/Omsättning" or "Höjning" special rules apply
    // When there is only one loan it should be set on question advice only
    if (this.scenario === Scenarios.Increase || this.scenario === Scenarios.Conditions) {
      const medium = terms[1]
      const long = terms[2]
      if (loans.length === 1) {
        terms[0] = long // Loans - 1 = 0
      }

      if (loans.length === 2) {
        terms[0] = medium  // 1
        terms[1] = long   // 2 == loans .length = 2 (loans. length - 1 => long
      }
      // If more than 1 one loan can be three months
      if (loans.length > 1 && this.twoPercent > 0) {
        terms[0] = TermsTexts.getThreeMonth()
      }
      return this.setTerms(loans, terms)
    }

    // How do you feel about changes?
    // 0 = Stable, no change wanted
    // 1 = Some but not too much
    // 2 = May vary
    if (this.questions.q2 === 0) {
      // Go stable
      // No 3-month loans accepted, and we return the suggested distribution
      return this.setTerms(loans, terms)
    }

    /*
      Om det finns överskott i KALPen vid räntehöjning med 2 %  föreslås 1 eller 2 lån med 3-mån ränta
      (1 lån om sökt belopp är fördelat på 2 lån, och 2 om lånebelopp är fördelat på 3)
      */
    if (this.twoPercent > 0) {
      terms[0] = TermsTexts.getThreeMonth()
      if (loans.length > 2 && this.questions.q2 === 2) {
        terms[1] = TermsTexts.getThreeMonth() // 3-month
      }
    }
    return this.setTerms(loans, terms)
  }

  public selectTerms(loans: KalpLoan[], terms?: any): void {
    // In the case of "Villkorsändring/Omsättning" special rules apply. Terms should be set on question advice only
    if (this.staticDataService.questions$.value.hasNewQuestions && this.scenario === Scenarios.Conditions) {
      for (let i = 0; i < loans.length; i++) {
        terms = this.calculateFactorAndTerms(i, loans.length).terms
      }
      this.setTerms(loans, terms)
    } else if (this.staticDataService.questions$.value.hasNewQuestions && terms) {
      this.setTerms(loans, terms)
    } else {
      this.legacyTerms(loans)
    }
  }

  /**
   * Make a new proposal. Can be requested from the outside
   */
  private update(): void {
    if (this.scenario === Scenarios.Conditions) {
      this.setLoans()
    } else {
      this.makeProposal()
    }
  }

  /**
   * Sets the terms based on q1
   * @param terms
   * @private
   */
  private getLoansFromQ1(terms: Terms[]): any {
    switch (this.questions.q1) {
    case 1: {
      terms[0] = TermsTexts.getThreeMonth() // 3 month
      terms[1] = TermsTexts.getThreeMonth() // 3 month
      terms[2] = TermsTexts.getThreeMonth() // 3 month
      break
    }
    case 3: {
      // 3 years on first 2 and 1 o remaining
      terms[0] = TermsTexts.getByMonths(12) // 1 year
      terms[1] = TermsTexts.getByMonths(24) // 2 years
      terms[2] = TermsTexts.getByMonths(36)// 3 years
      break
    }
    default: {
      // default handles both 5 and 10 years, since they get the same result
      terms[0] = TermsTexts.getByMonths(12) // 1 year
      terms[1] = TermsTexts.getByMonths(36) // 3 years
      terms[2] = TermsTexts.getByMonths(60) // 5 years
      break
    }
    }
  }

  private setTerms(loans: KalpLoan[], terms: Terms[]): void {
    loans.forEach((loan, index) => {
      Object.assign(loan, terms[index])
    })

    // If one loan, the loan should be the longest
    if (loans.length === 1) {
      Object.assign(loans[0], terms[2])
    }

    // If two loans, the last loan should be the longest loan.
    if (loans.length === 2) {
      Object.assign(loans[1], terms[2])
    }
  }

  private getNumberOfLoans(): number {
    let numberOfLoans = 1

    // Legacy fractions
    let fractions = [1000 * 1000, 1500 * 1000]

    /**
     * Change this when we no longer has legacy questions, but for now we only have this type of fraction
     * if we know customers use new questions
     */
    if (this.staticDataService.questions$.value.hasNewQuestions) {
      //Split for two loans is 1000 * 1000 and three loans are 2500 * 1000
      fractions = [1000 * 1000, 2500 * 1000]
    }

    fractions.forEach((i: number) => {
      if (this.loanAmount > i) {
        numberOfLoans++
      }
    })
    return numberOfLoans
  }

  /**
   * Calculates the number of months remaining until a loan expires.
   *
   * @param {KalpLoan} loan - The loan for which to determine the remaining months.
   * @returns {number} The remaining months until the loan expires.
   */
  private monthsLeft(loan: KalpLoan): number {
    // Get the current date in milliseconds
    const currentDate = new Date().getTime()

    // Define the approximate number of milliseconds in a month.
    const millisecondsInAMonth = 30.44 * 24 * 60 * 60 * 1000

    // Calculate the total months left until the loan expires by dividing the time difference
    // between the loan's expiration date and the current date by the milliseconds in a month.
    const totalMonths = Math.floor((loan.expires - currentDate) / millisecondsInAMonth)

    return totalMonths
  }


  private handleOldLoansSpecialCases(terms: Terms[], hasOddTerms: boolean, hasTwoYear: boolean): void {
    this.kalpService.kalpResult$.pipe(first()).subscribe({
      next: (kalp) => {
        const oldLoans = kalp[1].loans.loans.filter(loan => !loan.new)
        oldLoans.forEach(oldLoan => {
          const monthsLeft = this.monthsLeft(oldLoan)
          terms.forEach((term, i) => {
            if (monthsLeft > 0) {
              this.handleLoanExpiration(monthsLeft, term, terms, i, hasOddTerms, hasTwoYear)
            }
          })
        })
      }
    })
  }

  private handleLoanExpiration(monthsLeft: number, term: Terms, terms: Terms[], i: number, hasOddTerms: boolean, hasTwoYear: boolean): void {
    if (monthsLeft <= 12) {
      this.handleLoanExpirationWithin12Months(term, terms, i, hasTwoYear)
    } else if (monthsLeft <= 24) {
      this.handleLoanExpirationWithin24Months(term, terms, i, hasOddTerms)
    } else if (monthsLeft <= 36) {
      this.handleLoanExpirationWithin36Months(term, terms, i)
    }
  }

  private handleLoanExpirationWithin12Months(term: Terms, terms: Terms[], i: number, hasTwoYear: boolean): void {
    if (term.terms === 12) {
      terms[i] = TermsTexts.getByMonths(24)
    }

    if (term.terms === 24 && this.getNumberOfLoans() === 3 && !hasTwoYear) {
      terms[i] = TermsTexts.getByMonths(36)
    }
  }

  private handleLoanExpirationWithin24Months(term: Terms, terms: Terms[], i: number, hasOddTerms: boolean): void {
    if (term.terms === 24) {
      terms[i] = TermsTexts.getByMonths(36)
    }

    if (term.terms === 36 && !hasOddTerms) {
      terms[i] = TermsTexts.getByMonths(48)
    }
  }

  private handleLoanExpirationWithin36Months(term: Terms, terms: Terms[], i: number): void {
    if (term.terms === 36) {
      terms[i] = TermsTexts.getByMonths(48)
    }
  }

  private calculateFactorAndTerms(i: number, numberOfLoans: number): {
    factor: number;
    terms: Terms[]
  } {
    const stayFor = this.questions.q6

    const defaultTerms: Terms = {
      interestPercent: 0,
      text: 'Rörlig (3 mån)',
      terms: 3
    }
    const terms: Terms[] = [defaultTerms, defaultTerms, defaultTerms]
    terms[0] = TermsTexts.getThreeMonth()
    terms[1] = TermsTexts.getThreeMonth()
    terms[2] = TermsTexts.getThreeMonth()
    terms[3] = TermsTexts.getThreeMonth()
    terms[4] = TermsTexts.getByMonths(60) //Highest possible if we have >4 loans
    terms[5] = TermsTexts.getByMonths(60) //Highest possible if we have >5 loans
    // If we have >6 loans, they get three months

    const maxQ3andQ5 = Math.max(this.questions.q3, this.questions.q5)
    const maxQ4 = this.questions.q4

    const scenario = this.getScenario(stayFor, maxQ3andQ5, maxQ4)
    const hasOddTerms = scenario === 5 || scenario === 6
    const hasTwoYear = scenario === 4

    this.calculateTerms(terms, scenario, numberOfLoans)

    const factor = this.calculateFactor(scenario, numberOfLoans, i)

    /**
     * Perform very complex operations handling older loans. I've alerted the bank about potential issues arising
     * during question changes, despite our comprehensive set of test cases addressing this.
     * And also that future changes will be more difficult to make. Se this threads for reference:
     * https://linaf.slack.com/archives/C027NQXDG7Q/p1702295829478759
     * https://linaf.slack.com/archives/C027NQXDG7Q/p1706792569241249
     */
    this.handleOldLoansSpecialCases(terms, hasOddTerms, hasTwoYear)

    if (this.questions.q3 === -1 || this.questions.q4 === -1 || this.questions.q5 === -1) {
      this.handleUnansweredQuestions(terms)
    }

    return {factor, terms}
  }

  private calculateTerms(terms: Terms[], scenario: number, numberOfLoans: number): void {
    if (numberOfLoans === 1) {
      if (scenario === 1) {
        terms[2] = TermsTexts.getByMonths(12)
      } else if (scenario === 2 || scenario === 3 || scenario === 4) {
        terms[2] = TermsTexts.getByMonths(24)
      } else if (scenario === 5 || scenario === 6) {
        terms[2] = TermsTexts.getByMonths(36)
      }
    } else if (numberOfLoans === 2) {
      if (scenario === 1) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[2] = TermsTexts.getByMonths(12)
      } else if (scenario === 2) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[2] = TermsTexts.getByMonths(24)
      } else if (scenario === 3) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[2] = TermsTexts.getByMonths(24)
      } else if (scenario === 4) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[2] = TermsTexts.getByMonths(36)
      } else if (scenario === 5) {
        terms[0] = TermsTexts.getByMonths(12)
        terms[2] = TermsTexts.getByMonths(36)
      } else if (scenario === 6) {
        terms[0] = TermsTexts.getByMonths(12)
        terms[2] = TermsTexts.getByMonths(36)
      }
    } else if (numberOfLoans >= 3) {
      if (scenario === 1) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[1] = TermsTexts.getByMonths(12)
        terms[2] = TermsTexts.getByMonths(24)
        terms[3] = TermsTexts.getByMonths(24)
      } else if (scenario === 2 || scenario == 3) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[1] = TermsTexts.getByMonths(12)
        terms[2] = TermsTexts.getByMonths(24)
        terms[3] = TermsTexts.getByMonths(24)
      } else if (scenario === 4) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[1] = TermsTexts.getByMonths(24)
        terms[2] = TermsTexts.getByMonths(36)
        terms[3] = TermsTexts.getByMonths(36)
      } else if (scenario === 5) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[1] = TermsTexts.getByMonths(36)
        terms[2] = TermsTexts.getByMonths(60)
        terms[3] = TermsTexts.getByMonths(60)
      } else if (scenario === 6) {
        terms[0] = TermsTexts.getThreeMonth()
        terms[1] = TermsTexts.getByMonths(36)
        terms[2] = TermsTexts.getByMonths(60)
        terms[3] = TermsTexts.getByMonths(60)
      }
    }
  }

  private getScenario(stayFor: number, maxQ3andQ5: number, maxQ4: number): number {
    let scenario = 0
    if (stayFor === 2) {
      scenario = maxQ3andQ5
      if (maxQ4 === 1) {
        scenario = Math.max(2, scenario)
      } else if (maxQ4 === 2) {
        scenario = Math.max(1, scenario)
      } else if (maxQ4 === 3) {
        scenario = Math.max(3, scenario)
      }

      if (this.twoPercent < 0) {
        scenario = Math.max(2, scenario)
      }

    } else if (stayFor === 3) {
      //Below we +3 because we must use the extra dimension of the questions (se case 4-6 in scripts/scenarios.xls)
      scenario = maxQ3andQ5 + 3
      if (maxQ4 === 1) {
        scenario = Math.max(5, scenario)
      } else if (maxQ4 === 2) {
        scenario = Math.max(4, scenario)
      } else if (maxQ4 === 3) {
        scenario = Math.max(6, scenario)
      }

      if (this.twoPercent < 0) {
        scenario = Math.max(5, scenario)
      }

    }
    return scenario
  }

  private calculateFactor(scenario: number, numberOfLoans: number, i: number): number {
    let factor = 1
    if (numberOfLoans === 2) {
      factor = factor / 2

      if (scenario === 1) {
        factor = i === 0 ? 0.75 : 0.25
      } else if (scenario === 2) {
        factor = i === 0 ? 0.40 : 0.60
      } else if (scenario === 3) {
        factor = i === 0 ? 0.25 : 0.75
      } else if (scenario === 4) {
        factor = i === 0 ? 0.50 : 0.50
      } else if (scenario === 5) {
        factor = i === 0 ? 0.40 : 0.60
      } else if (scenario === 6) {
        factor = i === 0 ? 0.25 : 0.75
      }
    } else if (numberOfLoans === 3) {

      if (scenario === 0) {
        factor = factor / 3
      } else if (scenario === 1) {
        factor = i === 0 ? 0.50 : i === 1 ? 0.25 : 0.25
      } else if (scenario <= 3) {
        factor = i === 0 ? 0.25 : i === 1 ? 0.50 : 0.25
      } else if (scenario === 4) {
        factor = i === 0 ? 0.50 : i === 1 ? 0.25 : 0.25
      } else if (scenario === 5) {
        factor = i === 0 ? 0.25 : i === 1 ? 0.50 : 0.25
      } else if (scenario === 6) {
        factor = i === 0 ? 0.25 : i === 1 ? 0.50 : 0.25
      }
    }
    return factor
  }

  private handleUnansweredQuestions(terms: Terms[]): void {
    terms[0] = TermsTexts.getThreeMonth()
    terms[1] = TermsTexts.getThreeMonth()
    terms[2] = TermsTexts.getThreeMonth()
  }

  public makeProposal(): void {
    const numberOfLoans = this.getNumberOfLoans()
    const proposedLoans: KalpLoan[] = []

    let terms: Terms[] = []
    for (let i = 0; i < numberOfLoans; i++) {
      const factorAndTerms = this.calculateFactorAndTerms(i, numberOfLoans)


      terms = factorAndTerms.terms


      const proposedLoan: KalpLoan = new KalpLoan(this.loanAmount * factorAndTerms.factor)

      proposedLoan.new = true
      proposedLoans.push(proposedLoan)
    }
    this.selectTerms(proposedLoans, terms)

    /**
     * flatten the proposed loans to nearest 100 000
     * 966 669 becomes 700 000
     */
    proposedLoans.forEach(loan => {
      loan.amount = Math.round(loan.amount / (25 * 1000)) * 25 * 1000
    })
    const sumOfLoans = proposedLoans.reduce((sum, loan: KalpLoan) => sum + loan.amount, 0)
    // Just adjust the last loan to fill it up to the same as loan amount.
    if (sumOfLoans !== this.loanAmount) {
      const index = sumOfLoans - this.loanAmount < 0 ? 0 : proposedLoans.length - 1
      proposedLoans[index].amount += (this.loanAmount - sumOfLoans)
    }
    this.kalpService.getActiveBuilding().subscribe({
      next: (building: KalpBuilding) => {
        proposedLoans.forEach((loan: KalpLoan) => {
          loan.property = building.id
        })
        this.sendProposal(proposedLoans)
      }
    })
  }

  private sendProposal(loans: KalpLoan[]): void {
    const proposal: Proposal = {
      loans: loans,
      loanAmount: this.loanAmount,
      manual: false
    }
    this.kalpService.setOtherLoans(proposal) // Will trigger a re-kalp
    this.updateLoansWithMortgages(proposal.loans)

    /**
     * Needs to do another kalp calculation after proposal update
     */
    this.kalpService.makeKalp()
  }

  /**
   * Sets the loans based on existing loans that need to be omsatta.
   */
  private setLoans(): void {
    let buildingId: string
    this.kalpService.getActiveBuilding().pipe(
      first(),
      switchMap((newBuilding: KalpBuilding) => {
        buildingId = newBuilding.id
        return this.kalpService.loans$
      }),
      first()
    ).subscribe({
      next: (kalpLoans: KalpLoan[]) => {
        const loans: KalpLoan[] = kalpLoans
          .filter((loan: KalpLoan) => loan.solve && loan.property === buildingId)
          .map((loan: KalpLoan) => {
            const newLoan = new KalpLoan(loan.amount)
            newLoan.interestPercent = loan.interestPercent
            newLoan.property = buildingId
            newLoan.mortgageAmount = loan.mortgageAmount
            newLoan.new = true
            return newLoan
          })
        this.selectTerms(loans)
        this.sendProposal(loans)
      }
    })
  }

  /**
   * Sets the proper mortgage based on mortgage requirement and
   * @private
   */
  private updateLoansWithMortgages(loans: KalpLoan[]): void {
    let requirement: number
    this.getMortgageRequirement().pipe(
      switchMap((mortgageRequirement: number) => {
        requirement = mortgageRequirement
        return this.kalpService.getActiveBuilding()
      }
      ))
      .subscribe({
        next: (building: KalpBuilding) => {
          const mortgage = new Mortgage(requirement, building.mortgageRequirementDebt)
          // We do it like this to avoid error on empty arrays
          loans.forEach((loan: KalpLoan, index: number) => {
            if (index === 0) {
              // In case of scenario we stick with existing amortization
              if (this.scenario === Scenarios.Conditions) {
                return
              }
              loan.setMortgageAmount(Math.max(0, mortgage.mortgageAmount - building.currentMortgage))
            }
          })
          const proposal: Proposal = {
            loans: loans,
            loanAmount: this.loanAmount,
            manual: false
          }
          this.proposal$.next(proposal)
        }
      })
  }

  /**
   * The mortgage requirement based on building loans and the "debt quota"
   * 0, 1, 2 or 3 %. The debt quota is fetched from kalp service it can
   * give an extra percent. The "Building" calculates its own extra based
   * on the loans. So when we have the building, we set the loans on the building
   * and get extra amortization requirements for free.
   * @private
   */
  private getMortgageRequirement(): Observable<number> {
    return this.kalpService.getDebtQuota().pipe(
      first(),
      map((dbq: DebtQuota) => dbq.amortizationRequirement)
    )
  }
}
