<form [formGroup]="form">
  <mat-form-field class="email">
    <mat-label>E-post</mat-label>
    <input formControlName="email" matInput>
    @if (form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)) {
      <mat-error
        class="error-message-holder">
        Vänligen ange en giltigt e-postadress
      </mat-error>
    }
  </mat-form-field>

  <mat-form-field>
    <mat-label>Mobil</mat-label>
    <input formControlName="mobile" matInput>
    @if (form.get('mobile')?.invalid && (form.get('mobile')?.dirty || form.get('mobile')?.touched)) {

      <mat-error
        class="error-message-holder">
        Vänligen ange ett giltigt telefonnummer
      </mat-error>
    }
  </mat-form-field>
</form>
