import {AsyncPipe} from '@angular/common'
import {Component, OnDestroy, OnInit} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {MatMenu, MatMenuItem, MatMenuTrigger} from '@angular/material/menu'
import {Router, RouterLink} from '@angular/router'
import {LogoComponent} from '@sparbanken-syd/sparbanken-syd-theme'
import {Subscription} from 'rxjs'
import {
  CUSTOMERS_ROUTE_PATH,
  HOME_ROUTE_PATH
} from '../../application/data-types'
import {ConfigService} from '../../services/config.service'
import {ControlService} from '../../services/control.service'
import {DataService} from '../../services/data.service'
import {KalpService, Scenarios} from '../../services/kalp.service'
import {TestService} from '../../services/test.service'
import {TestComponent} from '../test/test.component'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, MatButton, TestComponent, MatMenuTrigger, MatMenu, MatMenuItem, AsyncPipe, LogoComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {
  public mainPath = '/' + HOME_ROUTE_PATH
  public customerPath = '/' + CUSTOMERS_ROUTE_PATH

  public scenario: Scenarios | null = null

  private scenario$ = new Subscription()

  constructor(
    public configService: ConfigService,
    public controlService: ControlService,
    public dataService: DataService,
    public testService: TestService,
    private kalpService: KalpService,
    private router: Router
  ) {
  }

  public ngOnInit(): void {
    this.scenario$ = this.kalpService.scenarioChange$.subscribe({
      next: (scenario: Scenarios | null) => this.scenario = scenario
    })
  }

  public ngOnDestroy(): void {
    this.scenario$.unsubscribe()
  }

  public reset(): void {
    this.dataService.reset()
    this.router.navigate(['/', HOME_ROUTE_PATH]).then()
  }
}
