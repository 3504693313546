import {AsyncPipe, NgClass} from '@angular/common'
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MatStep,
  MatStepContent,
  MatStepLabel,
  MatStepper,
  MatStepperNext,
  MatStepperPrevious
} from '@angular/material/stepper'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {Subscription} from 'rxjs'
import version from '../../../assets/package.json'
import {Plural} from '../../model/plural'
import {ControlService} from '../../services/control.service'
import {DataService} from '../../services/data.service'
import {KalpService, LegacyKalp} from '../../services/kalp.service'
import {StaticDataService} from '../../services/static-data.service'
import {TestService} from '../../services/test.service'
import {GeneralComponent} from '../1-general/general.component'
import {KalpComponent} from '../2-kalp/kalp.component'
import {LivingComponent} from '../3-living/living.component'
import {ExistingLoanComponent} from '../4-existingloan/existing-loan.component'
import {QuestionsComponent} from '../5-questions/questions.component'
import {PieChartComponent} from '../6a-piechart/pie-chart.component'
import {KalpResultComponent} from '../6b-0-kalp-result/kalp-result.component'
import {ProposalComponent} from '../7-proposal/proposal.component'
import {SummaryComponent} from '../8-summary/summary.component'
import {ChartLegendComponent} from '../chart-legend/chart-legend.component'
import {ScenarioComponent} from '../scenario/scenario.component'

@Component({
  selector: 'spb-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  imports: [NgClass, MatStepper, MatStep, MatStepLabel, MatStepContent, ScenarioComponent, GeneralComponent, MatButton, MatStepperNext, KalpComponent, MatStepperPrevious, LivingComponent, ExistingLoanComponent, QuestionsComponent, ChartLegendComponent, PieChartComponent, KalpResultComponent, ProposalComponent, SummaryComponent, AsyncPipe]
})
export class MainComponent extends Plural implements OnInit, OnDestroy {

  @ViewChild('thaStepper') stepper: MatStepper = {} as any

  public version = version.version

  public kalps: LegacyKalp[] = []

  public steps: Record<number, boolean> = {
    1: false,
    2: false,
    3: false,
    5: false,
    7: false
  }

  public hasDeclined = false

  private step$: Subscription = new Subscription()

  private declines$ = new Subscription()

  constructor(
    public kalpService: KalpService,
    public controlService: ControlService,
    public testService: TestService,
    public dataService: DataService,
    protected override staticDataService: StaticDataService,
    private route: ActivatedRoute
  ) {
    super(staticDataService)
  }


  public ngOnInit(): void {
    this.init()
    this.route.paramMap.subscribe({
      next: (params: ParamMap) => {
        if (params.get('id')) {
          this.dataService.populateCustomer(params.get('id') as string)
        }
      }
    })

    this.step$ = this.controlService.step$.pipe().subscribe({
      next: (index: number) => this.stepper.selectedIndex = index
    })

    this.declines$ = this.controlService.declines$.subscribe({
      next: (declines: boolean) => this.hasDeclined = declines
    })
  }

  public stepValid(valid: boolean, step: number) {
    setTimeout(() => this.steps[step] = valid, 1)
  }

  public decline() {
    this.controlService.decline(false)
    this.staticDataService.followAdvice$.next('')
  }

  public ngOnDestroy(): void {
    this.step$.unsubscribe()
    this.declines$.unsubscribe()
  }
}
