import {Routes} from '@angular/router'
import {authGuard} from './application/auth.guard'
import {
  CUSTOMERS_ROUTE_PATH,
  HOME_ROUTE_PATH,
  LOGIN_ROUTE_PATH
} from './application/data-types'
import {InterestResolver} from './application/interest.resolver'
import mainRoutes from './main/main-routes'

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    children: mainRoutes,
    canActivate: [authGuard],
    resolve: {
      latestInterest: InterestResolver
    }
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadComponent: () => import('./login/login/login.component').then(m => m.LoginComponent)
  },
  {
    path: CUSTOMERS_ROUTE_PATH,
    loadComponent: () => import('./customers/home/customers.component').then(m => m.CustomersComponent),
    canActivate: [authGuard]
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]