import {Injectable} from '@angular/core'
import {Subscription} from 'rxjs'
import {StaticDataService} from '../services/static-data.service'

@Injectable()
export class Plural {

  protected plural = false

  private plural$ = new Subscription()

  constructor(protected staticDataService: StaticDataService) {
  }

  get plrDuNi(): string {
    return this.plural ? 'Ni' : 'Du'
  }

  get plrDigEr(): string {
    return this.plural ? 'er' : 'dig'
  }

  get plrDinaEra(): string {
    return this.plural ? 'era' : 'dina'
  }

  get plrDinEr(): string {
    return this.plural ? 'Er' : 'Din'
  }

  get plrBekvamBekvama(): string {
    return this.plural ? 'bekväma' : 'bekväm'
  }

  get plrDittErt(): string {
    return this.plural ? 'ert' : 'ditt'
  }

  get plrJagVi(): string {
    return this.plural ? 'Vi' : 'Jag'
  }

  get plrMinaVara(): string {
    return this.plural ? 'våra' : 'mina'
  }

  get plrMinVar(): string {
    return this.plural ? 'vår' : 'min'
  }

  public init(): void {
    this.plural$ = this.staticDataService.plural$
      .pipe()
      .subscribe((plural: boolean) => this.plural = plural)
  }

  public destroy() {
    this.plural$.unsubscribe()
  }
}
