import {HttpClient} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {MatSnackBar} from '@angular/material/snack-bar'
import {LatestInterest} from '@sparbanken-syd/kalpylator'
import {EMPTY, Observable} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class InterestService {

  constructor(
    private snackBar: MatSnackBar,
    private httpClient: HttpClient
  ) {
  }

  /**
   * Get the latest valid interest from database
   */
  public getLatestInterest(): Observable<LatestInterest> {
    const url = `${environment.rtUrl}/interests/latest`
    return this.httpClient.get<LatestInterest>(url)
      .pipe(
        catchError(() => {
          this.snackBar.open('Ett fel har inträffat. Den senaste räntan är inte korrekt. Kontrollera din internetanslutning', 'OKEJ', {
            horizontalPosition: 'center',
            verticalPosition: 'top'
          })
          return EMPTY
        }),
        map((interest: LatestInterest) => interest)
      )
  }
}
