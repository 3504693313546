import {Property} from '@sparbanken-syd/kalpylator'
import {uuidV4} from '../application/data-types'
import {ObjectTypes, Scenarios} from '../services/kalp.service'
import {PROPERTY_COST_DEFAULTS} from '../services/ng.service'
import {KalpLoan} from './kalp-loan'
import {KalpylatorHelper} from './kalpylator-helper'

export enum BuildingTypes {
  /**
   * This is if this is the new property
   */
  purchase,

  /**
   * Type leave is the property to sell. Should not be used in Kalp new
   */
  leave,

  /**
   * Keep is the current house for other scenarios than buy
   */
  keep,
  /**
   * Other are just buildings that cost
   */
  other
}

export interface IKalpBuilding {

  heading: string

  type: BuildingTypes

  typeOfObject: ObjectTypes

  id: string

  valid: boolean

  fee: number

  objectValue: number

  mortgageRequirementValue?: number

  mortgageRequirementDebt?: number

  runCost: number
}

export class KalpBuilding implements IKalpBuilding {

  public heading = 'Övrigt boende'

  public type: BuildingTypes = BuildingTypes.other

  public typeOfObject = ObjectTypes.VILLA

  public id: string = uuidV4()

  /**
   * Only used for form controls etc
   */
  public valid = false

  public fee = 0

  // The value or purchase price of this property
  public objectValue = 0

  // The value for Amorteringsgrundande VÄRDE
  private pMortgageRequirementValue = 0

  // The value for Amorteringsgrundande SKULD
  private pMortgageRequirementDebt = -1

  // The total amount of loans on this property
  public loan = 0

  // The loans attached to this building
  public loans: KalpLoan[] = []

  // This is set on scenario conditions and increase to reflect the
  // amount paid in mortgage monthly on existing loans
  public currentMortgage = 0

  /**
   * This is needed sometimes. We need all loans on the building
   * That are NOT new but INCLUDING the SOLVED!
   */
  public allCurrentLoansOnBuilding = 0

  /**
   * Tells if this is the building under works. It is the
   * new Building if purchase it is the existing building in
   * all other cases
   */
  public primary

  private selectionMap: Record<string, number> = {}

  private pRunCost = -1

  constructor(
    heading = 'Övrigt boende',
    primary = false,
    type: BuildingTypes = BuildingTypes.other,
    typeOfObject = ObjectTypes.VILLA
  ) {
    this.heading = heading
    this.type = type
    this.primary = primary
    this.typeOfObject = typeOfObject
    this.selectionMap[ObjectTypes.HYRESRATT] = PROPERTY_COST_DEFAULTS['HYRES'].runCost
    this.selectionMap[ObjectTypes.VILLA] = PROPERTY_COST_DEFAULTS['VILLA'].runCost
    this.selectionMap[ObjectTypes.BOSTADSRATT] = PROPERTY_COST_DEFAULTS['BOSTADSRATT'].runCost
    this.selectionMap[ObjectTypes.FRITIDSHUS] = PROPERTY_COST_DEFAULTS['FRITIDSHUS'].runCost
    this.runCost = this.selectionMap[this.typeOfObject]
  }

  get runCost(): number {
    return Math.max(this.pRunCost, this.selectionMap[this.typeOfObject])
  }

  set runCost(runCost: number | undefined) {
    if (typeof runCost === 'number') {
      this.pRunCost = runCost
    }
  }

  get cash(): number {
    return this.objectValue - this.loan
  }

  /**
   * Degree is the "debt" / "value". The debt can be more than loans
   * and the value can be lower than the valuation.
   */
  get degree(): number {
    return this.mortgageRequirementDebt / this.mortgageRequirementValue
  }

  get monthlyCost(): number {
    return this.runCost + this.fee + this.propertyTax
  }

  get propertyTax(): number {
    const taxFreeTypes = [ObjectTypes.BOSTADSRATT, ObjectTypes.HYRESRATT]
    return taxFreeTypes.indexOf(this.typeOfObject) === -1 ? Math.ceil(9287 / 12) : 0
  }

  get mortgageRequirementValue(): number {
    return this.pMortgageRequirementValue ? this.pMortgageRequirementValue : this.objectValue
  }

  set mortgageRequirementValue(value: number | undefined) {
    if (typeof value === 'number') {
      this.pMortgageRequirementValue = value
    }
  }

  /**
   * Amorteringsgrundande skuld. The biggest of loan or the set value.
   */
  get mortgageRequirementDebt(): number {
    return this.pMortgageRequirementDebt > this.loan ? this.pMortgageRequirementDebt : this.loan
  }

  set mortgageRequirementDebt(value: number) {
    this.pMortgageRequirementDebt = value
  }

  /**
   * Calculates extra amortization requirement for
   * this building, returns 0, 0.01 or 0.02
   */
  get extra(): number {
    const degree = this.degree
    let result = 0
    const limits = [
      {degree: 0.5, result: 0.01},
      {degree: 0.7, result: 0.02}
    ]
    limits.forEach((limit: any) => {
      if (degree > limit.degree) {
        result = limit.result
      }
    })
    return result
  }

  /**
   * The sum of loan for all active loans. An active loan is a loan under
   * "rådgivning"
   */
  get activeLoanSum(): number {
    return this.loans
      .filter((loan: KalpLoan) => loan.active)
      .reduce((sum: number, loan: KalpLoan) => sum + loan.amount, 0)
  }

  /**
   * The loans that already on this property. This must include
   * the loans including the solved ones.
   */
  get currentLoanSum(): number {
    return this.loans
      .filter((loan: KalpLoan) => !loan.active)
      .reduce((sum: number, loan: KalpLoan) => sum + loan.amount, 0)
  }

  public setFromInput(input: IKalpBuilding): void {
    Object.assign(this, input)
  }

  public setBuildingFromKalpInput(input: Property): void {
    if (input.friendlyName) {
      this.heading = input.friendlyName
    } else {
      if (this.type === BuildingTypes.purchase) {
        this.heading = 'Nya bostaden'
      }

      if (this.type === BuildingTypes.keep) {
        this.heading = 'Uppgifter om bostaden'
      }
    }

    // Type 1 = leave, id must be 0?
    if (this.type === BuildingTypes.leave) {
      input.id = ''
    }

    this.id = input.id
    this.objectValue = input.marketValue ?? 0

    this.typeOfObject = KalpylatorHelper.convertPropertyTypeToLegacy(input.propertyType)
    this.mortgageRequirementValue = input.mortgageRequirementValue ?? 0
    this.mortgageRequirementDebt = input.mortgageRequirementDebt ?? 0
    this.runCost = input.runCost
    this.fee = input.rent ?? 0
    this.valid = true
  }

  /**
   * The loans that we receive should be all loans. The amount set
   * should reflect the situation after change and represent all loans
   * on the building. This so that we can calculate degree and hence
   * mortgage requirements.
   *
   * @param loans - All loans from Kalp new and old and blancos.
   * @param scenario - The selected scenario
   */
  public setLoans(loans: KalpLoan[], scenario: Scenarios): void {
    // Filtered are loans not blanco.
    let filtered: KalpLoan[] = loans.filter((loan: KalpLoan) => !loan.isBlancoLoan)

    this.allCurrentLoansOnBuilding = filtered
      .filter((loan: KalpLoan) => !loan.new && loan.property === this.id)
      .reduce((acc: number, loan: KalpLoan) => acc + loan.amount, 0)

    // When purchase we calculate on new loans.
    if (scenario === Scenarios.Purchase) {
      filtered = filtered
        .filter((loan: KalpLoan) => loan.new)
        .map((loan: KalpLoan) => {
          loan.active = true
          return loan
        })
    }

    /**
     * In case of transfer, don't include solved loans
     * are marked as active??
     */
    if (scenario === Scenarios.Transfer) {
      filtered = filtered
        .filter((loan: KalpLoan) => loan.new || !loan.solve) // Should not exist but could possibly
        .filter((loan: KalpLoan) => loan.property === this.id)
        .map((loan: KalpLoan) => {
          loan.active = loan.new
          return loan
        })
    }

    /**
     * In increase all new Loans, all old loans that are on this property but
     * not solved.
     */
    if (scenario === Scenarios.Increase) {
      filtered = filtered
        .filter((loan: KalpLoan) => loan.property === this.id || loan.new)
        .filter((loan: KalpLoan) => !loan.solve)

      filtered.forEach((loan: KalpLoan) => loan.active = loan.new)

      this.currentMortgage = loans
        .filter((loan: KalpLoan) => loan.property === this.id && !loan.new)
        .filter((loan: KalpLoan) => !loan.solve)
        .reduce((sum: number, loan: KalpLoan) => sum + loan.mortgageAmount, 0)
    }

    // When conditions we take all loans on this property solve and no solve.
    if (scenario === Scenarios.Conditions) {
      filtered = filtered
        .filter((loan: KalpLoan) => loan.new || !loan.solve) // Should not exist but could possibly
        .filter((loan: KalpLoan) => loan.property === this.id)

      // Sum up all loans with mortgage that are not "solve", that is not for renewal/conditions
      this.currentMortgage = filtered
        .filter((loan: KalpLoan) => !loan.solve)
        .reduce((sum: number, loan: KalpLoan) => sum + loan.mortgageAmount, 0)

      // In this scenario all loans that are "new" are to be considered
      // active
      filtered.forEach((loan: KalpLoan) => loan.active = loan.new)

    }
    this.loan = filtered.reduce((sum: number, loan: KalpLoan) => loan.amount + sum, 0)
    this.loans = filtered
  }
}


