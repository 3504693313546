import {Component, OnInit} from '@angular/core'
import {Router} from '@angular/router'

@Component({
  selector: 'spb-reset',
  template: '<div>Ulf</div>'
})
export class ResetComponent implements OnInit {

  constructor(
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.router.navigate(['/']).then()
  }

}
