<h2>{{ this.purpose.title }}</h2>
<div class="content">
  @for (p of purpose.items; track $index) {
    <div class="group">
      <div>{{ p.text }}</div>
      <div>{{ p.value }}</div>
    </div>
  }
</div>

<p></p>
<p></p>

<h4>Lån som omfattas av rådgivning</h4>
<div class="content">
  @for (p of purpose.loans; track $index) {
    <div class="group">
      <div>{{ p.text }}</div>
      <div>{{ p.value }}</div>
    </div>
  }
</div>
