import {Component, Inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from '@angular/material/dialog'
import {PrintService} from '../../services/print.service'

export interface PreviewResult {
  documentId: string
  status: 'cancel' | 'sign'
}

export interface PreviewInput {
  documentId: string
}

@Component({
  selector: 'spb-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: [],
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatButton, MatDialogClose]
})
export class PreviewDialogComponent {
  constructor(
    private printService: PrintService,
    @Inject(MAT_DIALOG_DATA) public data: PreviewInput) {
  }

  public preview(): void {
    this.printService.getLink(this.data.documentId, false).subscribe()
  }
}
