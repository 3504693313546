<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Amorteringskrav</mat-panel-title>
    <mat-panel-description>{{ amortizationAmount() | number: '1.0-2': 'fr' }} kr
      / månad
    </mat-panel-description>
  </mat-expansion-panel-header>
  <ng-template matExpansionPanelContent>
    <div class="main">
      <div>
        <table id="first">
          <caption style="display: none;"></caption>
          <th></th>
          <tr>
            <td>Amorteringskrav i %</td>
            <td
              class="number">{{ loan.mortgagePercent * 100 | number: '1.0-2': 'fr' }}
              %
            </td>
          </tr>
          <tr>
            <td>Amorteringskrav kr/månad</td>
            <td class="number">{{ loan.mortgageAmount | number: '1.0-2': 'fr' }}
              kr / månad
            </td>
          </tr>
          @if (!scenarioChange) {
            <tr class="small">
              <td>Varav amorteras på befintliga lån</td>
              <td
                class="number">{{ existingAmortization | number: '1.0-2': 'fr' }}
                kr / månad
              </td>
            </tr>
          }
          @if (!scenarioChange) {
            <tr class="small">
              <td>Varav lägsta belopp på nytt/nya lån<br>för att uppfylla
                amorteringskrav
              </td>
              <td
                class="number">{{ loan.mortgageAmount - existingAmortization | number: '1.0-2': 'fr' }}
                kr / månad
              </td>
            </tr>
          }
        </table>
        <table class="space" id="last">
          <caption style="display: none;"></caption>
          <th scope="col"></th>
          @if (scenarioIncrease) {
            <tr>
              <td colspan="2">Om du har bolån som är utbetalda före 1/3 2018<br>kan
                du istället för huvudregeln välja att
                ta ett Tilläggslån.
              </td>
            </tr>
          }
          @if (scenarioIncrease) {
            <tr class="small">
              <td>Amorteringskrav om kunden väljer tilläggslån</td>
              <td
                class="number">{{ additionalLoan.mortgageAmount | number: '1.0-0': 'fr' }}
                kr / månad
              </td>
            </tr>
          }
        </table>
      </div>
      <table id="second" style="height: fit-content;">
        <caption style="display: none;"></caption>

        <th scope="col"></th>
        <tr>
          <td>Belåningsgrad</td>
          <td class="number">{{ building.degree * 100 | number: '1.0-0': 'fr' }}
            %
          </td>
        </tr>
        <tr class="small">
          <td>Amorteringsgrundande skuld</td>
          <td
            class="number">{{ building.mortgageRequirementDebt | number: '1.0-0': 'fr' }}
            kr
          </td>
        </tr>
        <tr class="small">
          <td>Amorteringsgrundande värde</td>
          <td
            class="number">{{ building.mortgageRequirementValue | number: '1.0-0': 'fr' }}
            kr
          </td>
        </tr>
        <tr>
          <td>Skuldkvot</td>
          <td
            class="number">{{ debtQuota.quota / 100| number: '1.0-2': 'fr' }}
          </td>
        </tr>
        <tr class="small">
          <td>Skuldkvotsgrundande kreditbelopp</td>
          <td class="number">{{ debtQuota.loans | number: '1.0-0': 'fr' }} kr
          </td>
        </tr>
        <tr class="small">
          <td>Bruttoinkomst</td>
          <td
            class="number">
            {{ (debtQuota.income) | number: '1.0-0': 'fr' }}
            kr / år
          </td>
        </tr>
      </table>
    </div>
  </ng-template>
</mat-expansion-panel>
