import {Injectable} from '@angular/core'
import {Spouse} from '@sparbanken-syd/kalpylator'
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from 'rxjs'
import {filter, map, switchMap} from 'rxjs/operators'
import {BuildingTypes, KalpBuilding} from '../model/kalp-building'
import {KalpLoan} from '../model/kalp-loan'
import {ControlService} from './control.service'
import {KalpylatorFactory} from './kalpylator-factory'
import {Proposal} from './proposal.service'
import {StaticDataService} from './static-data.service'

export const KALP_BEFORE_CHANGE = 'Innan förändring'

export type TTypeOfKalp = 'existing' | 'new' | 'new2p'

export const getEmptyLegacyKalp = (): LegacyKalp => {
  return {
    applicantSpouses: false,
    applicants: [],
    buildings: [],
    carCount: 0,
    children: {
      count: 0,
      income: 0,
      cost: 0,
      childrenBenefit: 0,
      childrenBenefitExtra: 0
    },
    childrenAges: [],
    costOfBuildings: 0,
    current: false,
    debtQuota: {
      quota: 0,
      income: 0,
      loans: 0,
      amortizationRequirement: 0
    },
    haveCar: false,
    haveLeaseCar: false,
    income: {
      income: 0,
      tax: 0,
      net: 0,
      otherCosts: 0,
      taxFreeIncome: 0,
      studyLoan: 0,
      column: 0
    },
    incomes: [],
    kalp: 0,
    leaseCarCost: 0,
    livingExpense: 0,
    loans: {
      taxReturn: 0,
      cost: 0,
      loans: []
    },
    name: '',
    purchaseAmount: 0,
    reducedIncome: '',
    totalCost: 0,
    totalIncome: 0,
    costOfCars: 0
  }
}

export type TLegacyKalps = [LegacyKalp, LegacyKalp, LegacyKalp]

export interface LegacyKalp {
  /**
   * Only for carry on to save
   */
  childrenAges: number[]

  applicants: KalpApplicant[]
  // A summary object for the household totals
  income: KalpIncome
  incomes: KalpIncome[]
  children: KalpChildren
  loans: KalpLoans

  /**
   * Total living expense for the household.
   */
  livingExpense: number
  kalp: number
  debtQuota: DebtQuota

  /**
   * The sum of monthly cost of buildings, does not seem to be used
   * TODO: REMOVE
   */
  costOfBuildings: number
  buildings: KalpBuilding[]
  totalIncome: number
  totalCost: number

  /**
   * Car Info to be saved
   */
  haveCar: boolean
  carCount: number
  haveLeaseCar: boolean
  leaseCarCost: number
  /**
   * The purchase amount is the total loan we are looking for
   */
  purchaseAmount: number

  /**
   * Set a name for display purposes. Probably quite stupid and redundant
   * from the current below.
   */
  name: string

  /**
   * Set current if this is a KALP to use for further processing
   *
   * Set by the Kalp service, should be removed and not trusted
   *
   * TODO: REMOVE!
   */
  current: boolean

  /**
   * Reduced income is either not set or a text string
   */
  reducedIncome?: string

  applicantSpouses: boolean

  /**
   * The total cost of cars, per month
   */
  costOfCars: number
}

export interface DebtQuota {
  /**
   * A number 350, means that you have 3.5 times your income in loans
   */
  quota: number
  /**
   * Income before tax, including tax-free income.
   */
  income: number

  /**
   * Loans is the sum of loans.
   */
  loans: number

  /**
   * The total amortization requirement given both debt quota
   * and loan degree. This is given by the Kalpylator
   */
  amortizationRequirement: number
}

export interface KalpChildren {

  /**
   * The number of children
   */
  count: number

  /**
   * The SUM of all children benefit and "flerbarnstillägg"
   */
  income: number

  /**
   * The SUM of all children costs
   */
  cost: number

  /**
   * Total sum of barnbidrag
   */
  childrenBenefit: number

  /**
   * Sum of flerbarnstillägg
   */
  childrenBenefitExtra: number

}

export interface KalpIncome {
  /**
   * Gross income per month
   */
  income: number

  /**
   * Tax in SEK
   */
  tax: number

  /**
   * Income after tax
   */
  net: number

  /**
   * additional monthly costs
   */
  otherCosts: number

  /**
   * Other (tax-free) incomes
   */
  taxFreeIncome: number

  /**
   * This is the cost of study loans per individual per month.
   */
  studyLoan: number

  /**
   * Tax column, one of 1,2,3 (4,5) or 6
   */
  column: number
}

export interface KalpLoans {

  /**
   * Listing of all the loans.
   */
  loans: KalpLoan[]

  /**
   * This is the total cost of all loans, mortgage
   * and interest
   */
  cost: number

  /**
   * Tax deduction for all loans per month in SEK
   */
  taxReturn: number
}

export enum ObjectTypes {
  VILLA = 'Villa',
  BOSTADSRATT = 'Bostadsrätt',
  FRITIDSHUS = 'Fritidshus',
  HYRESRATT = 'Hyresrätt'
}

export enum Scenarios {
  Purchase = 'Köp av ny bostad',
  Increase = 'Höjning av befintliga lån',
  Transfer = 'Flytt av lån från annan bank',
  Conditions = 'Omsättning/Villkorsförändring'
}

export interface KalpApplicant {
  name: string
  personNummer: string
  income?: number
  studyLoan?: number
  taxFreeIncome?: number
  retired?: boolean
  reducedIncome: boolean

  hasSpouse: boolean
  spouse?: Spouse
  akassa: boolean
  employer: string
}

export interface KalpInput {

  applicants: KalpApplicant[]

  /**
   * The mumber of children under 18 in the house hold should always be present and set to 0 if none
   */
  childrenCount: number

  /**
   * An array of children ages.
   * We need two since the "children" array in the result
   * is occupied by the KalpChildren array. We should
   * refactor but that will hurt the saved BRs.
   */
  children: number[]
  childrenAges: number[]
  /**
   * If applicants are spouses
   */
  applicantSpouses: boolean

  buildings: KalpBuilding[]

  /**
   * Monthly income before tax. Total for object
   */
  income: number

  /**
   * Other costs as an amount on the house hold. SEK/Month
   */
  otherCosts: number

  /**
   * More details about hte income.
   */
  incomes: KalpIncome[]

  /**
   * A list of private loans.
   */
  privateLoans: KalpLoan[]

  /**
   * A list of additional housing loans.
   */
  otherMortgageLoans: KalpLoan[]

  /**
   * Total cost per month in SEK, set to 0 if none
   */
  studyLoan: number

  /**
   * This is the number we want new loans for
   */
  loanAmount: number

  /**
   * The value of the property, as selling price or
   * valuated
   */
  objectValue: number

  /**
   * Other living cost, additional from the calculated values
   * Give as SEK/Month
   */
  otherLivingCost: number

  haveCar: boolean
  carCount: number
  haveLeaseCar: boolean
  leaseCarCost: number

}

@Injectable({
  providedIn: 'root'
})
export class KalpService {

  public scenarioChange$ = new ReplaySubject<Scenarios | null>(1)

  /**
   * On this subject anyone can publish new KALP data. This is
   * general data about the customer income and costs.
   */
  public kalpData$ = new Subject<any>()

  /**
   * Publish new kalp calculus here
   */
  public kalpResult$: Observable<[LegacyKalp, LegacyKalp, LegacyKalp]>

  /**
   * Buildings are read and received here.
   */
  public buildings$ = new BehaviorSubject<KalpBuilding[]>([])

  /**
   * Loan amount, this is the amount that will be used
   */
  public loanAmount$ = new BehaviorSubject<number>(0)

  /**
   * This is where we submit manually entered loans.
   */
  public loanInput$ = new BehaviorSubject<KalpLoan[]>([])

  /**
   * A subject emitting all loans, mortgage, new loans and blanco
   */
  public loans$ = new ReplaySubject<KalpLoan[]>(1)

  public applicantCount$: BehaviorSubject<number> = new BehaviorSubject<number>(1)


  private pKalpResult$: BehaviorSubject<[LegacyKalp, LegacyKalp, LegacyKalp]> = new BehaviorSubject<[LegacyKalp, LegacyKalp, LegacyKalp]>([
    getEmptyLegacyKalp(), getEmptyLegacyKalp(), getEmptyLegacyKalp()
  ])
  /**
   * The master data of all and everything
   * Gets populates as each component triggers a subject.next
   */
  private kalpData: KalpInput = {incomes: []} as any

  private scenario: Scenarios | null = Scenarios.Purchase

  private newMortgageLoans: KalpLoan[] = []

  private loanAmount = 0

  private kalpQueue$ = new ReplaySubject<any>(1)

  constructor(
    private controlService: ControlService,
    private staticDataService: StaticDataService,
    private kalpFactory: KalpylatorFactory
  ) {
    this.kalpResult$ = this.pKalpResult$.asObservable()
    this.resetKalpData()
    /**
     * Note that we are service and give f**k all about unsubscribing to this.
     */
    this.kalpData$
      .subscribe({
        next: (kalpInput: KalpInput) => {
          // this.makeNumeric(kalpInput)
          /**
           * Special handling of NG Kalpylator transfer
           */
          kalpInput.childrenAges = kalpInput.children

          Object.assign(this.kalpData, kalpInput)
          this.kalpQueue$.next(true)

          /**
           * Trigger loanAmount when something changes in "2 Inkomster/Kostnader"
           */
          this.loanAmount$.next(this.loanAmount$.value)
        }
      })

    this.loanInput$.subscribe({
      next: (loans: KalpLoan[]) => {
        this.kalpData.otherMortgageLoans = loans.filter(l => !l.isBlancoLoan)
        this.kalpData.privateLoans = loans.filter(l => l.isBlancoLoan)
        this.kalpQueue$.next(true)
      }
    })

    this.scenarioChange$
      .pipe(
        filter((s: Scenarios | null): s is Scenarios => !!s)
      )
      .subscribe({
        next: (scenario: Scenarios) => {
          this.scenario = scenario
          this.controlService.scenarioSelected$.next(true)
        }
      })

    let building: KalpBuilding

    /**
     * We listen to our own buildings
     */
    this.buildings$.pipe(
      map((buildings: KalpBuilding[]) => buildings
        .filter((b: KalpBuilding) =>
          // Only continue if we have a real building to work with.
          b.type === BuildingTypes.keep || b.type === BuildingTypes.purchase)),
      filter((buildings: KalpBuilding[]) => buildings.length === 1),
      switchMap((buildings: KalpBuilding[]) => {
        building = buildings[0]
        return this.loanAmount$
      }),
      filter((loanAmount: number) => loanAmount > 0)
    ).subscribe({
      next: (loanAmount: number) => {
        this.loanAmount = loanAmount
        if (this.newMortgageLoans.length === 0) {
          const loan = new KalpLoan(loanAmount)
          loan.new = true
          loan.property = building.id
          this.newMortgageLoans = [loan]
        }
        // If we get real "buildings" we re-calp.
        this.kalpQueue$.next(true)
      }
    })

    this.kalpQueue$.subscribe({
      next: () => this.makeKalpNotWar()
    })
  }

  /**
   * When kalp needs to be updated outside the service
   */
  public makeKalp() {
    this.makeKalpNotWar()
  }

  /**
   * Returns two debt quotas, current and future. The debt quota
   * is the same for new and new 2%
   */
  public getDebtQuota(): Observable<DebtQuota> {
    return of(this.pKalpResult$.value[1].debtQuota)
  }

  /**
   * The "active" building is the primary object. It is the building that
   * we purchase or the one that we keep.
   */
  public getActiveBuilding(): Observable<KalpBuilding> {
    const building = new KalpBuilding()
    const buildingType = this.scenario === Scenarios.Purchase ? BuildingTypes.purchase : BuildingTypes.keep
    building.type = buildingType
    const existingBuilding = this.buildings$.value.find((b: KalpBuilding) => b.type === buildingType)
    if (existingBuilding) {
      building.setFromInput(existingBuilding)
    }
    building.setLoans(this.newMortgageLoans.concat(this.kalpData.otherMortgageLoans), this.scenario as Scenarios)
    return of(building)
  }

  /**
   *
   * @param proposal
   */
  public setOtherLoans(proposal: Proposal): void {
    this.newMortgageLoans = proposal.loans
    this.staticDataService.newLoans$.next(proposal.loans)
    this.kalpQueue$.next(true)
  }

  /**
   * Converts all "number like" property values in the supplied input
   * to numbers... Handles primitives and arrays and objects.
   */
  public makeNumeric(input: any): void {
    Object.keys(input).forEach((keyName: string) => {
      if (Array.isArray(input[keyName])) {
        input[keyName].forEach((arrayItem: any) => {
          this.makeNumeric(arrayItem)
        })
      } else if (typeof input[keyName] === 'object' &&
        input[keyName] !== null) {
        this.makeNumeric(input[keyName])
      } else {
        if (typeof input[keyName] !== 'boolean') {
          input[keyName] = isNaN(+input[keyName]) ? input[keyName] : +input[keyName]
        }
      }
    })
  }

  public reset(): void {
    this.resetKalpData()
    this.loanAmount$.next(0)
    this.scenarioChange$.next(null)
    this.loans$.next([])
    this.buildings$.next([])
    this.newMortgageLoans = []
    this.kalpQueue$.next(true)
    this.staticDataService.contactInfo$.next([{
      email: '',
      mobile: ''
    }, {email: '', mobile: ''}])
  }

  private resetKalpData(): void {
    this.kalpData = {
      applicants: [],
      childrenCount: 0,
      children: [],
      childrenAges: [],
      applicantSpouses: false,
      buildings: [new KalpBuilding()],
      income: 0,
      incomes: [],
      privateLoans: [],
      studyLoan: 0,
      otherMortgageLoans: [],
      otherCosts: 0,
      loanAmount: 0,
      objectValue: 0,
      otherLivingCost: 0,
      haveCar: false,
      carCount: 0,
      haveLeaseCar: false,
      leaseCarCost: 0
    }
    this.kalpData$.next(this.kalpData)
  }

  private makeKalpNotWar(): void {
    /**
     * Make sure to update our "loans$" all loans
     * private, mortgage and our new loans.
     */
    this.loans$.next(this.kalpData.otherMortgageLoans.concat(this.kalpData.privateLoans, this.newMortgageLoans))
    // First calculate initial value, here we must set all loans to not solve
    // Since this represents today. We need a copy so that we do not change anything.
    this.kalpData.loanAmount = this.loanAmount
    this.staticDataService.plural$.next(this.kalpData.applicants.length > 1)


    const initialKalp: KalpInput = this.generateCopy()
    // Remove all new buildings
    initialKalp.buildings = this.buildings$.value.filter((building: KalpBuilding) => building.type !== BuildingTypes.purchase)
    const nowKalp = this.kalpFactory.getKalpylator(initialKalp, 0, 'existing').calculate()
    nowKalp.name = KALP_BEFORE_CHANGE
    nowKalp.current = true

    // Secondly we add the new loan to the mix, again we need a copy so that
    // We do not destroy the result
    const kalpCopy: KalpInput = this.generateCopy()

    /**
     * Include the new loans but remove the loans that are marked as solved?
     */
    kalpCopy.otherMortgageLoans = kalpCopy.otherMortgageLoans.concat(this.newMortgageLoans)
      .filter((loan: KalpLoan) => !loan.solve)
    kalpCopy.privateLoans = kalpCopy.privateLoans.filter(l => !l.solve)
    // Remove the old buildings if any
    kalpCopy.buildings = this.buildings$.value.filter((building: KalpBuilding) => building.type !== BuildingTypes.leave)
    const newKalp = this.kalpFactory.getKalpylator(kalpCopy, 0, 'new').calculate()
    newKalp.name = 'Efter förändring'

    // Finally, run the same again with 2 percent more expensive interest.
    const newKalp2p = this.kalpFactory.getKalpylator(kalpCopy, 0.02, 'new2p').calculate()
    newKalp2p.name = 'Efter förändring + 2%'
    this.pKalpResult$.next([nowKalp, newKalp, newKalp2p])
  }

  private generateCopy(): KalpInput {
    const res = JSON.parse(JSON.stringify(this.kalpData))
    const copy = (input: KalpLoan) => {
      const c = new KalpLoan(input.amount, input.new)
      c.setFromInput(input)
      return c
    }
    // The loans are destroyed in the copy process and has to be fixed.
    res.privateLoans = res.privateLoans.map((l: KalpLoan) => copy(l))
    res.otherMortgageLoans = res.otherMortgageLoans.map((l: KalpLoan) => copy(l))
    return res
  }
}
