<table class="new-loan-holder">
  <caption style="display: none;">caption</caption>
  <tr class="row header">
    <th class="plain-header" id="0"></th>
    <th id="2">Belopp</th>
    <th id="5">Amortering</th>
    <th id="3">Ränta %</th>
    <th id="4">Ränta</th>
    <th id="1">Räntebindning</th>
    <th id="6">Villkorsändringsdag</th>
    <th id="7">
      {{ scenarioChange ? 'Ändras' : 'Nytt' }}
    </th>

  </tr>
  @for (loan of loans; let i = $index; track i) {
    <tr>
      <td class="box">
        <div
          [style.background-color]="colorList[i]"
          class="color-box"></div>
      </td>
      <td class="number">{{ loan.amount | number: '1.0-2':'fr' }} kr</td>
      <td>{{ loan.mortgageAmount | number: '1.0-2':'fr' }} kr</td>
      <td>{{ loan.interestPercent * 100 | number: '1.0-2':'fr' }} %</td>
      <td>{{ loan.interestAmount | number: '1.0-0':'fr' }} kr</td>
      <td class="left">
        @if (loan.terms === 3) {
          <span>3 månader</span>
        } @else if (loan.terms === 12) {
          <span>1 år</span>
        } @else if (loan.terms === 24) {
          <span>2 år</span>
        } @else if (loan.terms === 36) {
          <span>3 år</span>
        } @else if (loan.terms === 48) {
          <span>4 år</span>
        } @else if (loan.terms === 60) {
          <span>5 år</span>
        }
      </td>
      <td>{{ loan.changeDate }}{{ loan.active ? '*' : '' }}</td>
      <td class="left">
        <mat-checkbox [checked]="loan.active" disabled></mat-checkbox>
      </td>
    </tr>
  }
  <tr class="bold">
    <td class="sum" colspan="1">S:a:</td>
    <td>{{ loanCost[0] | number: '1.0-0':'fr' }} kr</td>
    <td>{{ loanCost[1] | number: '1.0-0':'fr' }} kr</td>
    <td></td>
    <td>{{ loanCost[2] | number: '1.0-0':'fr' }} kr</td>
  </tr>
</table>
