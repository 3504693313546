<div matDialogTitle>{{ data.title }}</div>
<div matDialogContent>
  <div>Handläggare:</div>
  <div
    class="margin-bottom-small">{{ customerService.currentCustomer$.value?.user?.name }}
  </div>
  <div>Låntagare:</div>
  @if (customerService.currentCustomer$.value!.saveData!.applicants!.applicants!.length > 0) {
    @for (applicant of customerService.currentCustomer$.value?.saveData?.applicants?.applicants; track applicant.name) {
      <div>{{ applicant.name }}</div>
    }
  }

  <p class="margin-top-small">{{ data.message }}</p>
</div>
<div matDialogActions>
  <button
    (click)="unLockAdvice()"
    color="primary"
    mat-raised-button>Ja
  </button>
  <button [mat-dialog-close]="undefined" mat-raised-button>Avbryt</button>
</div>