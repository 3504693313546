<h2>Hur tänker {{ plrDuNi.toLowerCase() }} kring {{ plrDittErt.toLowerCase() }}
  boende?</h2>
<form [formGroup]="form" class="spb-form">
  @if (!(staticDataService.questions$ | async)?.hasNewQuestions) {
    <div>
      <h3>Hur länge planerar du att bo kvar i bostaden?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 1)"
        aria-label="Hur länge planerar du att bo kvar i bostaden"
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q1result === 1" value="1">Mindre än 1 år
        </mat-radio-button>
        <mat-radio-button [checked]="q1result === 3" value="3">Ca 3 år
        </mat-radio-button>
        <mat-radio-button [checked]="q1result === 10" value="10">Mer än 5 år
        </mat-radio-button>
      </mat-radio-group>
      <h3>Hur känner du inför din boendekostnad över tid?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 2)"
        aria-label="Hur känner du inför din boendekostnad över tid? "
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q2result === 0" value="0">Du vill veta vad
          du ska betala varje månad
        </mat-radio-button>
        <mat-radio-button [checked]="q2result === 1" value="1">Den får variera
          en del över tid men inte för mycket
        </mat-radio-button>
        <mat-radio-button [checked]="q2result === 2" value="2">Du är bekväm med
          att den varierar över tid
        </mat-radio-button>
      </mat-radio-group>
    </div>
  }
  @if ((staticDataService.questions$ | async)?.hasNewQuestions) {
    <div>
      <h3>Hur skulle hushållets ekonomi påverkas om något inträffar som
        påverkar {{ plrDinaEra.toLowerCase() }} inkomster
        eller utgifter på ett väsentligt sätt?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 3)"
        aria-label="Hur skulle hushållets ekonomi påverkas om något inträffar som påverkar dina inkomster eller utgifter
      på ett väsentligt sätt?"
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q3result === 1" value="1">{{ plrJagVi }}
          kan
          hantera stora förändringar utan att det
          påverkar {{ plrMinVar.toLowerCase() }} möjlighet att betala räntor och
          amorteringar på
          {{ plrMinaVara.toLowerCase() }} bolån
        </mat-radio-button>
        <mat-radio-button [checked]="q3result === 2" value="2">{{ plrJagVi }}
          kan
          hantera vissa förändringar utan att det
          påverkar {{ plrMinVar.toLowerCase() }} möjlighet att betala räntor och
          amorteringar på
          {{ plrMinaVara.toLowerCase() }} bolån
        </mat-radio-button>
        <mat-radio-button [checked]="q3result === 3" value="3">{{ plrJagVi }}
          har
          små marginaler och kan bara hantera
          mindre förändringar utan att det
          påverkar {{ plrMinVar.toLowerCase() }} möjlighet att betala räntor och
          amorteringar på {{ plrMinaVara.toLowerCase() }} bolån
        </mat-radio-button>
      </mat-radio-group>

      <h3>Tror {{ plrDuNi.toLowerCase() }} att hushållets ekonomi kommer att
        påverkas i stor grad de närmaste tre åren?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 4)"
        aria-label="Tror du att hushållets ekonomi kommer att påverkas negativt de närmaste tre åren?"
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q4result === 1" value="1">Nej
        </mat-radio-button>
        <mat-radio-button [checked]="q4result === 2" value="2">Ja, på ett
          positivt sätt ({{ plrJagVi.toLowerCase() }} kommer
          att få ökade inkomster och/eller minskade kostnader utöver det
          normala)
        </mat-radio-button>
        <mat-radio-button [checked]="q4result === 3" value="3">Ja, på ett
          negativt sätt ({{ plrJagVi.toLowerCase() }}
          kommer att få minskade inkomster och/eller ökade kostnader utöver det
          normala)
        </mat-radio-button>
      </mat-radio-group>

      <h3>Har hushållet en buffert i form av sparande eller andra tillgångar så
        att ni kan klara ökade
        räntekostnader?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 5)"
        aria-label="Har hushållet en buffert i form av sparande eller andra tillgångar så att ni kan klara ökade räntekostnader?"
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q5result === 1" value="1">{{ plrJagVi }}
          kan
          hantera stora räntehöjningar under en lång
          period med hjälp av sparande eller annat
        </mat-radio-button>
        <mat-radio-button [checked]="q5result === 2" value="2">{{ plrJagVi }}
          kan
          hantera räntehöjningar i viss omfattning
          och under en begränsad period med hjälp av sparande eller annat
        </mat-radio-button>
        <mat-radio-button [checked]="q5result === 3" value="3">{{ plrJagVi }}
          kan
          endast hantera mindre räntehöjningar och
          under en begränsad period med hjälp av sparande eller annat
        </mat-radio-button>
      </mat-radio-group>

      <h3>Hur länge planerar {{ plrDuNi.toLowerCase() }} att bo kvar i
        bostaden?</h3>
      <mat-radio-group
        (change)="changeRadioButton($event, 6)"
        aria-label="Hur länge planerar du att bo kvar i bostaden?"
        color="primary"
        ngDefaultControl>
        <mat-radio-button [checked]="q6result === 1" value="1">Mindre än ett
          år
        </mat-radio-button>
        <mat-radio-button [checked]="q6result === 2" value="2">Mer än ett år,
          men mindre än tre år
        </mat-radio-button>
        <mat-radio-button [checked]="q6result === 3" value="3">Mer än tre år
        </mat-radio-button>
      </mat-radio-group>
    </div>
  }
</form>
